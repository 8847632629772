import React, { useState, useEffect, useRef } from "react";
import { Accordion, Button, Icon, Table } from "semantic-ui-react";
import { updateTenantSettings } from "../../../../BytebeamClient";
import { beamtoast } from "../../../common/CustomToast";
import { ShowTabs, ShowTabsData, TenantSettings, User } from "../../../../util";
import { showTabResolver, showTabsDataConfig } from "./ShowTabsConfig";
import ToggleWithLoadingIndicator from "./ToggleWithLoadingIndicator";

import _ from "lodash";
import styled from "styled-components";
import { StyledHeader } from "../../Actions/ActionsV3/SelectableItem";
import { ThinDivider } from "../../Dashboards/Panel/util";

export const ModuleStyledAccordion = styled.div`
  margin-top: 20px;
  background-color: ${({ theme }) =>
    theme.colors["container-background"]} !important;
  padding: 20px !important;
  width: 100% !important;
  color: ${({ theme }) => theme.colors["foreground-color"]};
  border: ${({ theme }) => theme.colors["container-border"]};
  border-radius: 12px !important;
  box-sizing: border-box;
  box-shadow: ${({ theme }) => theme.colors["container-box_shadow"]};
  &:hover {
    box-shadow: ${({ theme }) => theme.colors["container-box_shadow-hover"]};
  }
`;

type ModulePermissionsProps = {
  user: User;
  getCurrentUser: () => Promise<void>;
};

const ModulePermissions = (props: ModulePermissionsProps) => {
  const { user, getCurrentUser } = props;

  const [showTabs, setShowTabs] = useState<ShowTabs>(showTabsDataConfig);
  const [updateTabsLoading, setUpdateTabsLoading] = useState<boolean>(false);
  const initialShowTabsRef = useRef<ShowTabs | null>(null);
  const [areObjectsEqual, setAreObjectsEqual] = useState<boolean>(true);
  const [isModulePermissionsActive, setIsModulePermissionsActive] =
    useState<boolean>(false);

  const handleModulePermissionsAccordianClick = () => {
    setIsModulePermissionsActive((prev) => !prev);
  };

  const resetShowTabsDataConfigState = (
    showTabsDataConfig,
    showTabsDataUser
  ) => {
    for (const key in showTabsDataConfig) {
      if (
        typeof showTabsDataConfig[key] === "object" &&
        typeof showTabsDataUser[key] === "object"
      ) {
        if (showTabsDataUser[key].hasOwnProperty("show")) {
          showTabsDataConfig[key].show = showTabsDataUser[key].show;
        }
        resetShowTabsDataConfigState(
          showTabsDataConfig[key],
          showTabsDataUser[key]
        );
      }
    }
    return showTabsDataConfig;
  };

  useEffect(() => {
    const isShowTabsDataPresent =
      user?.["tenant-settings"]?.show_tabs &&
      Object.keys(user?.["tenant-settings"]?.show_tabs).length > 0;
    const showTabsDataUser = isShowTabsDataPresent
      ? (user?.["tenant-settings"]?.show_tabs as ShowTabs)
      : showTabsDataConfig;

    const updatedShowTabsDataConfigState: ShowTabsData =
      resetShowTabsDataConfigState(showTabsDataConfig, showTabsDataUser);

    setShowTabs({ ...updatedShowTabsDataConfigState });

    initialShowTabsRef.current = showTabsDataUser;
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (initialShowTabsRef.current) {
      setAreObjectsEqual(_.isEqual(initialShowTabsRef.current, showTabs));
    }
  }, [showTabs]);

  function toggleTabRecursive(updatedTabs: ShowTabs, tabName: string) {
    for (const key in updatedTabs) {
      if (key === tabName) {
        updatedTabs[key].show = !updatedTabs[key].show;
        break;
      } else if (typeof updatedTabs[key] === "object") {
        toggleTabRecursive(updatedTabs[key] as ShowTabs, tabName);
      }
    }
    return updatedTabs;
  }

  const toggleTab = async (tabName: string) => {
    const updatedTabs = _.cloneDeep(showTabs);
    const UpdatedTabsData = toggleTabRecursive(updatedTabs, tabName);
    setShowTabs({ ...UpdatedTabsData });
  };

  async function updateShowTabsTenantSettings(showTabs) {
    const tenant_settings = user?.["tenant-settings"] ?? ({} as TenantSettings);
    setUpdateTabsLoading(true);
    try {
      const res = await updateTenantSettings({
        settings: {
          ...tenant_settings,
          show_tabs: showTabs,
        },
      });

      if (res !== undefined && res !== null) {
        await getCurrentUser();
        beamtoast.success(`Show Tabs updated successfully`);
      }
    } catch (err) {
      beamtoast.error(`Error occurred when updating Show Tabs`);
    } finally {
      setUpdateTabsLoading(false);
    }
  }

  // Check if there are nested tabs with the "show" property set to true
  // For tabName, it only checks the nested tabs of that specific tab.
  const hasNestedTabsWithShowTrue = (
    tabs: ShowTabs,
    tabName?: string
  ): boolean => {
    return Object.values(tabs).some(
      (tabSettings) =>
        (!tabName || tabSettings === tabs[tabName]) &&
        tabSettings?.show &&
        Object.values(tabSettings).some(
          (subTabSettings) => typeof subTabSettings === "object"
        )
    );
  };

  const renderSubtabs = (tabs: ShowTabs) => {
    // Filter out valid subtabs
    const validSubtabs = Object.entries(tabs).filter(
      ([subTabName, subTabSettings]) =>
        subTabName !== "show" && typeof subTabSettings === "object"
    );

    if (validSubtabs.length > 0) {
      return (
        <Table celled>
          <Table.Body>
            {validSubtabs.map(([subTabName, subTabSettings]) => (
              <Table.Row key={subTabName}>
                <Table.Cell>{showTabResolver[subTabName]}</Table.Cell>
                <Table.Cell width={2}>
                  <ToggleWithLoadingIndicator
                    updateTabsLoading={updateTabsLoading}
                    tabSettings={subTabSettings}
                    tabName={subTabName}
                    toggleTab={toggleTab}
                  />
                </Table.Cell>
                {hasNestedTabsWithShowTrue(tabs, subTabName) ? (
                  <Table.Cell>
                    {renderSubtabs(subTabSettings as ShowTabs)}
                  </Table.Cell>
                ) : (
                  hasNestedTabsWithShowTrue(tabs) && <Table.Cell></Table.Cell>
                )}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      );
    }
    return null;
  };

  return (
    <ModuleStyledAccordion>
      <Accordion fluid>
        <Accordion.Title
          active={isModulePermissionsActive}
          onClick={handleModulePermissionsAccordianClick}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <StyledHeader
              as="h3"
              style={{
                margin: "0px",
                fontSize: "1.1rem",
                whiteSpace: "nowrap",
              }}
            >
              Modules Enabled
            </StyledHeader>
            <Icon
              style={{ fontSize: "24px" }}
              name={isModulePermissionsActive ? "angle down" : "angle right"}
            />
          </div>
        </Accordion.Title>
        <Accordion.Content active={isModulePermissionsActive}>
          <ThinDivider style={{ margin: "15px 0px 15px 0px" }} />

          <div>
            <Table
              unstackable
              celled
              style={{ opacity: updateTabsLoading ? 0.6 : 1 }}
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Module Name</Table.HeaderCell>
                  <Table.HeaderCell>Enabled</Table.HeaderCell>
                  {hasNestedTabsWithShowTrue(showTabs) && (
                    <Table.HeaderCell>Sub modules</Table.HeaderCell>
                  )}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {Object.entries(showTabs)
                .filter(([tabName]) => tabName !== "device_components")
                .map(([tabName, tabSettings]) => {
                  const hasNestedTabs = hasNestedTabsWithShowTrue(showTabs);
                  const showSubtabs = hasNestedTabsWithShowTrue(
                    showTabs,
                    tabName
                  );
                  return (
                    <Table.Row key={tabName}>
                      <Table.Cell width={hasNestedTabs ? undefined : 8}>
                        {showTabResolver[tabName]}
                      </Table.Cell>
                      <Table.Cell width={hasNestedTabs ? 2 : 8}>
                        <ToggleWithLoadingIndicator
                          updateTabsLoading={updateTabsLoading}
                          toggleTab={toggleTab}
                          tabSettings={tabSettings}
                          tabName={tabName}
                        />
                      </Table.Cell>
                      {showSubtabs ? (
                        <Table.Cell>
                          {renderSubtabs(tabSettings as ShowTabs)}
                        </Table.Cell>
                      ) : (
                        hasNestedTabs && <Table.Cell></Table.Cell>
                      )}
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
            {(!areObjectsEqual || updateTabsLoading) && (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  type="button"
                  disabled={updateTabsLoading}
                  onClick={() => updateShowTabsTenantSettings({ ...showTabs })}
                  primary
                >
                  Save Changes
                </Button>
              </div>
            )}
          </div>
        </Accordion.Content>
      </Accordion>
    </ModuleStyledAccordion>
  );
};

export default ModulePermissions;
