import React, { useState, useEffect, ReactNode } from "react";
import { Button, Modal } from "semantic-ui-react";
import { ErrorMessage } from "../roles/CreateOrEditRoleModal";
import { DeviceComponent, IDeviceComponent } from "../../../../BytebeamClient";
import { StyledInput, StyledLabel } from "../actiontypes/ActionTypesModal";
import { onlyAlphanumericValidation } from "../../../../util";

type DeviceComponentErrorType = {
  isError: boolean;
  message: string;
};

interface CreateOrEditDeviceComponentModalProps {
  readonly operationType: "Create" | "Edit";
  readonly component: IDeviceComponent;
  readonly allComponentsName: Set<string>;
  readonly onSubmit: (component: DeviceComponent) => void;
  readonly trigger: ReactNode;
}

export default function CreateOrEditDeviceComponentModal(
  props: CreateOrEditDeviceComponentModalProps
) {
  const [open, setOpen] = useState(false);
  const [component, setComponent] = useState<DeviceComponent>({
    name: props.component?.name ?? "",
  });
  const [error, setError] = useState<DeviceComponentErrorType>({
    isError: false,
    message: "",
  });

  const handleSubmit = () => {
    component.name = component.name.trim();
    let name = component.name;

    if (name.length === 0) {
      setError({
        isError: true,
        message: "component name is required",
      });
    } else if (!onlyAlphanumericValidation(name)) {
      setError({
        isError: true,
        message:
          "component name can be only alphanumeric, spaces and underscores are not allowed",
      });
    } else if (name === "main") {
      setError({
        isError: true,
        message: 'component name can not be "main"',
      });
    } else if (
      props.operationType === "Edit" &&
      props.component.name === name
    ) {
      setError({
        isError: true,
        message: "component name can not be same as previous value",
      });
    } else if (props.allComponentsName.has(name)) {
      setError({
        isError: true,
        message: "component name already exist",
      });
    } else {
      setError({
        isError: false,
        message: "",
      });
      onModalClose();
      props.onSubmit(component);
    }
  };

  const onModalClose = () => {
    setOpen(false);
    if (props.component.name === "") {
      setComponent((prevValues) => ({
        ...prevValues,
        name: "",
      }));
    }

    setError({
      isError: false,
      message: "",
    });
  };

  // Set the component state when the modal is opened
  useEffect(() => {
    if (open)
      setComponent({
        name: props.component?.name ?? "",
      });
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      className="dark"
      onClose={() => onModalClose()}
      onOpen={() => setOpen(true)}
      open={open}
      size="mini"
      trigger={props.trigger}
    >
      <Modal.Header>{`${props.operationType} Device Component`}</Modal.Header>
      <Modal.Content>
        <StyledInput labelPosition="left">
          <StyledLabel>Name</StyledLabel>
          <input
            autoFocus
            placeholder="Engine, BMS, etc..."
            value={component.name}
            onChange={(e) => {
              setComponent({
                ...component,
                name: e.target.value,
              });
            }}
          />
        </StyledInput>
        {error.isError && error.message !== "" && (
          <ErrorMessage id={"componentNameErrorSpan"}>
            {error.message}
          </ErrorMessage>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          secondary
          onClick={() => {
            onModalClose();
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          primary
          onClick={() => {
            handleSubmit();
          }}
        >
          Submit
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
