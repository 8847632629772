import React, { useState, useEffect } from "react";
import { Table, Button, Grid, Popup, MenuItem } from "semantic-ui-react";
import { ErrorMessage } from "../../../../common/ErrorMessage";
import {
  fetchAllFirmwares,
  downloadFirmware,
  FirmwareType,
  fetchAllDeviceComponents,
  IDeviceComponent,
  IDeviceComponentResult,
  fetchAllFirmwareBundles,
  FirmwareBundleResult,
  FirmwareBundle,
  fetchAllComponentsFirmwares,
  downloadComponentFirmware,
} from "../../../../../BytebeamClient";
import ActivateFirmwareModal from "./ActivateFirmwareModal";
import {
  ButtonIcon,
  DisplayIf,
  getHumanReadableFileSizeString,
} from "../../../util";
import CreateOrEditFirmwareModal from "./CreateOrEditFirmwareModal";
import Toggle from "../../../../common/Toggle";
import LoadingAnimation from "../../../../common/Loader";
import { useUser } from "../../../../../context/User.context";
import BrowserUpdatedIcon from "../../../../../assets/svg/BrowserUpdatedIcon";
import BackupIcon from "../../../../../assets/svg/BackupIcon";
import SlicedTextPopUp from "../../../DeviceManagement/Devices/SlicedTextPopUp";
import {
  StyledSemanticTabs,
  StyledTabPaneNoBorder,
} from "../../../Dashboards/Panel/util";
import { FirmwareBundles } from "./FirmwareBundles";

type FirmwareVersionsTableProps = {
  readonly firmwareVersions: FirmwareType[];
  readonly toggleIsLocked: boolean;
  readonly setToggleIsLocked: (isLocked: boolean) => void;
  readonly setFirmwareVersion: (firmware: FirmwareType) => void;
  readonly openEditFirmwareModal: () => void;
  readonly openActivateFirmwareModal: () => void;
  readonly fillFirmwareTable: (showDeactivatedFirmwares?: boolean) => void;
  readonly setShowDeactivated: (showDeactivated: boolean) => void;
  readonly showDeactivated: boolean;
  readonly openCreateFirmwareModal: () => void;
};

function FirmwareVersionsTable(props: FirmwareVersionsTableProps) {
  const {
    firmwareVersions,
    toggleIsLocked,
    setToggleIsLocked,
    setFirmwareVersion,
    openEditFirmwareModal,
    openActivateFirmwareModal,
    fillFirmwareTable,
    setShowDeactivated,
    showDeactivated,
    openCreateFirmwareModal,
  } = props;
  const { user } = useUser();
  const permissions = user?.role?.permissions || {};
  const componentEnabled =
    user?.["tenant-settings"]?.show_tabs?.device_components?.show ?? false;

  async function downloadFile(data: Blob, fileName: string) {
    try {
      const element = document.createElement("a");
      const file = new Blob([data], {
        type: "text/plain",
      });

      element.href = URL.createObjectURL(file);
      element.download = fileName;
      document.body.appendChild(element);
      setTimeout(() => element.click());
    } catch (error) {
      console.log("Error while creating file: ", error);
    }
  }

  async function downloadFirmwareByVersion(firmware: FirmwareType) {
    const version = firmware.version_number,
      file = firmware.file_name,
      component = firmware.device_component_name as string;
    try {
      if (componentEnabled) {
        const res = await downloadComponentFirmware(component, version);
        const blob: Blob = await res?.blob();
        downloadFile(blob, `firmware_${version}-${file}`);
      } else {
        const res = await downloadFirmware(version);
        const blob: Blob = await res?.blob();
        downloadFile(blob, `firmware_${version}-${file}`);
      }
    } catch (error) {
      console.log("Error while downloading firmware: ", error);
    }
  }

  async function handleShowDeactivatedToggle() {
    setShowDeactivated(!showDeactivated);
    fillFirmwareTable(!showDeactivated);
  }

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width="6" style={{ padding: "0px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
              marginTop: "16px",
              fontWeight: "bold",
            }}
          >
            <div>Show Deactivated</div>
            <div>
              <Toggle
                id="showDeactivatedFirmware"
                checked={showDeactivated}
                onClick={async () => await handleShowDeactivatedToggle()}
                style={{
                  top: "3px",
                }}
              />
            </div>
          </div>
        </Grid.Column>
        <Grid.Column width="6" floated="right" style={{ padding: "0px" }}>
          <DisplayIf cond={permissions.editFirmwares}>
            <Button
              id="upload_firmware_button"
              primary
              floated="right"
              onClick={() => {
                openCreateFirmwareModal();
              }}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "18px",
                whiteSpace: "nowrap",
              }}
            >
              <BackupIcon height="16px" altColor="#fff" />
              Upload New Firmware
            </Button>
          </DisplayIf>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column style={{ padding: "0px" }}>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="center">
                  Version Number
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  File Name
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  File Size
                </Table.HeaderCell>
                {!componentEnabled && (
                  <Table.HeaderCell textAlign="center">
                    Uncompressed File Size
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell textAlign="center">
                  Created At
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  Created By
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Checksum</Table.HeaderCell>
                <DisplayIf cond={permissions.editFirmwares}>
                  <Table.HeaderCell textAlign="center">
                    Options
                  </Table.HeaderCell>
                </DisplayIf>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {firmwareVersions.length !== 0 ? (
                firmwareVersions.map((firmware, index) => {
                  return (
                    <Table.Row key={firmware.version_number}>
                      <Table.Cell textAlign="center">
                        <SlicedTextPopUp
                          text={firmware.version_number}
                          length={18}
                        />
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <SlicedTextPopUp
                          text={firmware.file_name}
                          length={14}
                        />
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {getHumanReadableFileSizeString(
                          firmware.content_length
                        )}
                      </Table.Cell>
                      {!componentEnabled && (
                        <Table.Cell textAlign="center">
                          {firmware.uncompressed_size
                            ? getHumanReadableFileSizeString(
                                firmware.uncompressed_size
                              )
                            : "--"}
                        </Table.Cell>
                      )}
                      <Table.Cell textAlign="center">
                        {new Date(firmware.created_at).toLocaleString()}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <SlicedTextPopUp
                          text={firmware.created_by}
                          length={14}
                        />
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {firmware.checksum ? (
                          <SlicedTextPopUp
                            text={firmware.checksum}
                            length={14}
                          />
                        ) : (
                          "N/A"
                        )}
                      </Table.Cell>
                      <DisplayIf cond={permissions.editFirmwares}>
                        <Table.Cell
                          textAlign="center"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "18px",
                          }}
                        >
                          {!componentEnabled && (
                            <Popup
                              trigger={
                                <ButtonIcon
                                  name="edit"
                                  title="Edit Firmware Version"
                                  disabled={firmware.is_deactivated}
                                  style={{
                                    marginBottom: "0.7rem",
                                    marginRight: "0",
                                    cursor: firmware.is_deactivated
                                      ? "default"
                                      : "pointer",
                                  }}
                                  onClick={() => {
                                    if (!firmware.is_deactivated) {
                                      setFirmwareVersion(firmware);
                                      openEditFirmwareModal();
                                    }
                                  }}
                                />
                              }
                              content={
                                firmware.is_deactivated
                                  ? "Firmware is deactivated"
                                  : "Edit Firmware Version"
                              }
                              position="top center"
                              inverted
                            />
                          )}
                          <Popup
                            trigger={
                              <BrowserUpdatedIcon
                                height="14px"
                                id={`downloadFirmwareIcon${index}`}
                                style={{
                                  cursor: "pointer",
                                  opacity: 0.7,
                                }}
                                onClick={() => {
                                  downloadFirmwareByVersion(firmware);
                                }}
                              />
                            }
                            content="Download Firmware Version"
                            position="top center"
                            inverted
                          />
                          <Popup
                            trigger={
                              <Toggle
                                id={`${firmware.version_number} deactivateFirmwareToggle`}
                                checked={!firmware.is_deactivated}
                                isLocked={toggleIsLocked}
                                onClick={() => {
                                  openActivateFirmwareModal();
                                  setFirmwareVersion(firmware);
                                  setToggleIsLocked(true);
                                }}
                              />
                            }
                            content={`Click to ${
                              firmware.is_deactivated
                                ? "activate"
                                : "deactivate"
                            } firmware version`}
                            position="top center"
                            inverted
                            style={{ whiteSpace: "nowrap" }}
                          />
                        </Table.Cell>
                      </DisplayIf>
                    </Table.Row>
                  );
                })
              ) : (
                <Table.Row>
                  <Table.Cell
                    textAlign="center"
                    colspan={permissions.editFirmwares ? "8" : "7"}
                  >
                    <ErrorMessage
                      marginTop="30px"
                      message={"No Firmwares Uploaded!"}
                    />
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

function FirmwareVersions() {
  const { user } = useUser();
  const componentEnabled =
    user?.["tenant-settings"]?.show_tabs?.device_components?.show ?? false;

  const [loading, setLoading] = useState<boolean>(true);
  const [firmwareBundleLoading, setFirmwareBundleLoading] =
    useState<boolean>(true);
  const [firmwareVersion, setFirmwareVersion] = useState<FirmwareType>(
    {} as FirmwareType
  );
  const [firmwareVersions, setFirmwareVersions] = useState<FirmwareType[]>(
    [] as FirmwareType[]
  );
  const [firmwareBundles, setFirmwareBundles] = useState<FirmwareBundle[]>([]);

  const [allExistingFirmwareVersions, setAllExistingFirmwareVersions] =
    useState<FirmwareType[]>([] as FirmwareType[]);
  const [activateFirmwareModalIsOpen, setActivateFirmwareModalIsOpen] =
    useState<boolean>(false);
  const [createFirmwareModalIsOpen, setCreateFirmwareModalIsOpen] =
    useState<boolean>(false);
  const [editFirmwareModalIsOpen, setEditFirmwareModalIsOpen] =
    useState<boolean>(false);
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);
  const [showDeactivated, setShowDeactivated] = useState<boolean>(false);

  const [toggleIsLocked, setToggleIsLocked] = useState<boolean>(true);

  const [componentsList, setComponentsList] = useState<IDeviceComponent[]>([]);
  const [loadingComponentList, setLoadingComponentList] =
    useState<boolean>(true);
  const [selectedComponent, setSelectedComponent] = useState<string>("");
  const [activeIndex, setActiveIndex] = useState<number | string>(0);

  // Create Firmware Modal
  const openCreateFirmwareModal = () => {
    setCreateFirmwareModalIsOpen(true);
  };
  const closeCreateFirmwareModal = () => {
    setCreateFirmwareModalIsOpen(false);
  };

  // Edit Firmware Modal
  const openEditFirmwareModal = () => {
    setEditFirmwareModalIsOpen(true);
  };
  const closeEditFirmwareModal = () => {
    setEditFirmwareModalIsOpen(false);
  };

  // Activate Firmware Modal
  const openActivateFirmwareModal = () => {
    setActivateFirmwareModalIsOpen(true);
  };
  const closeActivateFirmwareModal = () => {
    setActivateFirmwareModalIsOpen(false);
  };

  async function fetchAllComponents() {
    setLoadingComponentList(true);
    try {
      const res: IDeviceComponentResult = await fetchAllDeviceComponents();
      setComponentsList(res.results);
      return res;
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingComponentList(false);
    }
  }

  async function fillFirmwareTable(showDeactivatedFirmwares?: boolean) {
    setLoading(true);
    try {
      const response = await fetchAllFirmwares();
      const filteredResponse = response.filter(
        (version) =>
          (typeof showDeactivatedFirmwares === "boolean"
            ? showDeactivatedFirmwares
            : showDeactivated) || !version.is_deactivated
      );
      setAllExistingFirmwareVersions(response);
      setFirmwareVersions(filteredResponse);
    } catch (error) {
      setErrorOccurred(true);
    } finally {
      setLoading(false);
    }
  }

  async function fillComponentsFirmwareTable(
    showDeactivatedFirmwares?: boolean
  ) {
    setLoading(true);
    try {
      const response = await fetchAllComponentsFirmwares();
      const filteredResponse = response.filter(
        (version) =>
          (typeof showDeactivatedFirmwares === "boolean"
            ? showDeactivatedFirmwares
            : showDeactivated) || !version.is_deactivated
      );
      setAllExistingFirmwareVersions(response);
      setFirmwareVersions(filteredResponse);
    } catch (error) {
      setErrorOccurred(true);
    } finally {
      setLoading(false);
    }
  }

  async function fillFirmwareBundlesTable() {
    setFirmwareBundleLoading(true);
    try {
      const res: FirmwareBundleResult = await fetchAllFirmwareBundles();
      setFirmwareBundles(res.results);
    } catch (error) {
      setErrorOccurred(true);
    } finally {
      setFirmwareBundleLoading(false);
    }
  }

  useEffect(() => {
    async function initialFetch() {
      if (componentEnabled) {
        await fetchAllComponents();
        await fillFirmwareBundlesTable();
        await fillComponentsFirmwareTable();
      } else {
        await fillFirmwareTable();
      }
    }

    initialFetch();
  }, [componentEnabled]); // eslint-disable-line react-hooks/exhaustive-deps

  if (errorOccurred) {
    return (
      <div
        style={{
          height: "65vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ErrorMessage
          marginTop="30px"
          errorMessage
          message="You do not have permissions to view Firmwares."
        />
      </div>
    );
  }

  if (loading || (firmwareBundleLoading && componentEnabled)) {
    return (
      <LoadingAnimation
        loaderContainerHeight="65vh"
        fontSize="1.5rem"
        loadingText="Loading Firmware Versions"
      />
    );
  }

  // Create panes for the Tab component based on componentsList
  const panes = [
    { id: -1, name: "Firmware Bundles", is_deleted: false },
    ...componentsList,
  ].map((component) => ({
    menuItem: <MenuItem key={component?.id}>{component.name}</MenuItem>,
    render: () => {
      const filteredFirmwareVersions = firmwareVersions.filter(
        (firmware) => firmware?.device_component_name === component.name
      );

      return (
        <StyledTabPaneNoBorder key={component?.name}>
          {component.name === "Firmware Bundles" ? (
            <FirmwareBundles
              firmwareBundles={firmwareBundles}
              fillFirmwareBundlesTable={fillFirmwareBundlesTable}
              componentsList={componentsList.map((component) => component.name)}
              firmwareVersions={firmwareVersions}
              loadingComponentList={loadingComponentList}
            />
          ) : (
            <FirmwareVersionsTable
              firmwareVersions={filteredFirmwareVersions}
              toggleIsLocked={toggleIsLocked}
              setToggleIsLocked={setToggleIsLocked}
              setFirmwareVersion={setFirmwareVersion}
              openEditFirmwareModal={openEditFirmwareModal}
              openActivateFirmwareModal={openActivateFirmwareModal}
              fillFirmwareTable={fillComponentsFirmwareTable}
              setShowDeactivated={setShowDeactivated}
              showDeactivated={showDeactivated}
              openCreateFirmwareModal={openCreateFirmwareModal}
            />
          )}
        </StyledTabPaneNoBorder>
      );
    },
  }));

  return (
    <>
      {/* Modal for activating and deactivating firmware */}
      <ActivateFirmwareModal
        isOpen={activateFirmwareModalIsOpen}
        close={closeActivateFirmwareModal}
        firmwareVersion={firmwareVersion}
        fillFirmwareTable={
          componentEnabled ? fillComponentsFirmwareTable : fillFirmwareTable
        }
        toggleHandler={() => setToggleIsLocked(!toggleIsLocked)}
      />

      {/* Modal for creating new firmware version */}
      <CreateOrEditFirmwareModal
        type="Add"
        isOpen={createFirmwareModalIsOpen}
        close={closeCreateFirmwareModal}
        allExistingFirmwareVersions={allExistingFirmwareVersions}
        fillFirmwareTable={
          componentEnabled ? fillComponentsFirmwareTable : fillFirmwareTable
        }
        componentsEnabled={componentEnabled}
        componentsList={componentsList.map((component) => component.name)}
        component={selectedComponent}
      />

      {/* Modal for creating new firmware version */}
      <CreateOrEditFirmwareModal
        type="Edit"
        selectedFirmwareVersion={firmwareVersion}
        isOpen={editFirmwareModalIsOpen}
        close={closeEditFirmwareModal}
        allExistingFirmwareVersions={allExistingFirmwareVersions}
        fillFirmwareTable={
          componentEnabled ? fillComponentsFirmwareTable : fillFirmwareTable
        }
        componentsEnabled={componentEnabled}
        componentsList={componentsList.map((component) => component.name)}
        component={selectedComponent}
      />

      {componentEnabled ? (
        <StyledSemanticTabs
          panes={panes}
          activeIndex={activeIndex}
          onTabChange={(e, data) => {
            setActiveIndex(data.activeIndex ?? activeIndex);
            if (data.activeIndex > 0) {
              setSelectedComponent(componentsList[data.activeIndex - 1].name);
            }
          }}
          style={{ marginTop: "24px" }}
        />
      ) : (
        <FirmwareVersionsTable
          firmwareVersions={firmwareVersions}
          toggleIsLocked={toggleIsLocked}
          setToggleIsLocked={setToggleIsLocked}
          setFirmwareVersion={setFirmwareVersion}
          openEditFirmwareModal={openEditFirmwareModal}
          openActivateFirmwareModal={openActivateFirmwareModal}
          fillFirmwareTable={fillFirmwareTable}
          setShowDeactivated={setShowDeactivated}
          showDeactivated={showDeactivated}
          openCreateFirmwareModal={openCreateFirmwareModal}
        />
      )}
    </>
  );
}

export default FirmwareVersions;
