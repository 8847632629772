import React from "react";

import {
  Modal,
  Button,
  Tab,
  Input,
  Dropdown,
  Icon,
  DropdownProps,
  DropdownDivider,
  Label,
  Radio,
  Segment,
  Popup,
  DropdownHeader,
} from "semantic-ui-react";

import { Permission, Role, User } from "../../../../util";
import styled from "styled-components";
import { capitalizeFirstLetter } from "../../util";
import { homepageOptions } from "./homePageOptions";

import {
  fetchDeviceFilterOptions,
  fetchAllActionTypes,
  fetchAllRoles,
  fetchAllMetadataKeys,
  fetchAllStreamsWithDetails,
  fetchAllDashboards,
  DashboardAPIResponse,
} from "../../../../BytebeamClient";
import { AddAllColText } from "../../../common/commonStyledComps";
import { DropdownOptionType } from "../../DeviceManagement/Devices/ActionModals/UpdateConfigModal";
const _ = require("lodash");

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: flex-start;
`;

const BottomRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-self: flex-end;
  margin-top: auto;
  justify-content: flex-end;
`;

const PaneContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const EditRoleDropdownContainer = styled.div`
  max-width: 300px;
  flex-grow: 1;
  margin-right: 20px;
  display: inline-block;
`;

const BorderlessPane = styled(Segment)`
  border: none;
`;

export const ErrorMessage = styled.span`
  color: #f77;
  font-weight: 500;
  position: relative;
  top: -8px;
  left: 4px;
`;

const HeadText = styled.h4`
  font-weight: 800;
  font-size: 1.2em;
  margin-bottom: 0px;
  margin-top: 0px;
  margin-right: 10px;
`;

const DropDownErrorDivWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  max-width: 300px;
  flex-direction: column;
`;

const HeaderWithPopup = ({ headerContent, popupContent }) => {
  return (
    <Row>
      <div style={{ display: "flex", alignItems: "center" }}>
        <HeadText>{headerContent}</HeadText>
        <Popup
          inverted
          trigger={
            <Icon name="question circle" style={{ marginBottom: "2px" }} />
          }
          content={popupContent}
          position="top center"
        />
      </div>
    </Row>
  );
};

const NoteWithPopup = ({ noteContent, popupContent }) => {
  return (
    <Row>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <p style={{ margin: "0px" }}>{noteContent}</p>
        <Popup
          inverted
          trigger={
            <Icon name="question circle" style={{ marginBottom: "2px" }} />
          }
          content={popupContent}
          position="top center"
        />
      </div>
    </Row>
  );
};

export const EditRoleDropdown = (props: DropdownProps) => (
  <EditRoleDropdownContainer>
    <Dropdown {...props} />
  </EditRoleDropdownContainer>
);

function SettingsPane(props: React.PropsWithChildren<{ onNext?: () => void }>) {
  return (
    <PaneContainer>
      {props.children}

      {props.onNext !== undefined ? (
        <BottomRow>
          <Button primary onClick={props.onNext}>
            Next
          </Button>
        </BottomRow>
      ) : null}
    </PaneContainer>
  );
}

type RoleConfigProps = {
  name: string;
  homepage: any;
  showDeviceManagementTab: boolean;
  showDashboardsTab: boolean;
  showActionsTab: boolean;
  dashboards: DashboardAPIResponse[];
  roleNameError: { error: boolean; message: string };
  user: User;
};

type RoleConfigState = {
  showDeviceManagementTab: boolean;
  showDashboardsTab: boolean;
  showActionsTab: boolean;
  roleHomepage: any;
};

class GeneralConfig extends React.Component<RoleConfigProps, RoleConfigState> {
  roleNameRef = React.createRef<HTMLInputElement>();

  constructor(props) {
    super(props);

    this.state = {
      showDeviceManagementTab: this.props.showDeviceManagementTab,
      showDashboardsTab: this.props.showDashboardsTab,
      showActionsTab: this.props.showActionsTab,
      roleHomepage: this.props.homepage,
    };
  }

  getRoleName() {
    return this.roleNameRef.current?.value;
  }

  getHomepage() {
    return this.state.roleHomepage;
  }

  shouldShowDeviceManagmentTab() {
    return this.state.showDeviceManagementTab;
  }

  shouldShowDashboardsTab() {
    return this.state.showDashboardsTab;
  }

  shouldShowActionsTab() {
    return this.state.showActionsTab;
  }

  render() {
    const dashboardOptions = [
      ...homepageOptions,

      {
        key: "DashboardsDivider",
        text: "DashboardsDivider",
        value: "DashboardsDivider",
        content: (
          <DropdownHeader
            content="Dashboards Options"
            icon="list alternate outline"
          ></DropdownHeader>
        ),
        disabled: true,
      },
      {
        key: "/dashboards",
        value: "/dashboards",
        text: "List of Dashboards",
      },

      ...this.props.dashboards.map((db: DashboardAPIResponse) => {
        // filter dashboards according to permissions (if hidden from admin, admin should not see apart from owner)
        const userIdString = "user:" + this.props.user.id || "xx"; // Replace 'user.id' with the actual current user's ID
        const roleString = "role:" + this.props.user.role.id || "xx"; // Replace 'user.role' with the actual current user's role
        const owners = db.config.dashboardMeta?.owners;

        let value: DropdownOptionType = { key: "", text: "", value: "" };

        if (db?.config?.dashboardMeta?.hideFromAdmins) {
          if (
            owners &&
            (owners.includes(userIdString) || owners.includes(roleString))
          ) {
            value = {
              key: `${db.id}`,
              value: `/dashboards/${db.id}`,
              text: `Dashboard: ${db.config.dashboardMeta.title}`,
            };
          }
        } else {
          value = {
            key: `${db.id}`,
            value: `/dashboards/${db.id}`,
            text: `Dashboard: ${db.config.dashboardMeta.title}`,
          };
        }
        return value;
      }),
    ];
    if (
      !this.state.showDashboardsTab &&
      !this.state.showDeviceManagementTab &&
      !this.state.showActionsTab
    ) {
      this.setState({
        showDeviceManagementTab: true,
      });
    }

    let homepageOptionsToShow = dashboardOptions.filter((option) => {
      if (option.text === "") return false;
      // Condition for showing dashboards tab
      if (!this.state.showDashboardsTab && option.text.includes("Dashboard")) {
        return false;
      }
      // Condition for showing device management tab
      if (
        !this.state.showDeviceManagementTab &&
        option.text.includes("Devices")
      ) {
        return false;
      }
      // Condition for showing actions tab
      if (!this.state.showActionsTab && option.value.startsWith("/actions")) {
        return false;
      }
      return true;
    });

    return (
      <React.Fragment>
        <Row>
          <Input labelPosition="left" style={{ width: "350px" }}>
            <Label>Role Name</Label>
            <input defaultValue={this.props.name} ref={this.roleNameRef} />
          </Input>
        </Row>
        {this.props.roleNameError.error ? (
          <ErrorMessage id={"roleErrorSpan"}>
            {this.props.roleNameError.message}
          </ErrorMessage>
        ) : (
          ""
        )}

        {/* <Divider /> */}

        <Row>
          <label style={{ marginRight: "10px" }}>
            {" "}
            Show Device Management Tab{" "}
          </label>
          <Radio
            toggle
            checked={this.state.showDeviceManagementTab}
            onChange={(_event, data) => {
              this.setState({ showDeviceManagementTab: data.checked || false });
            }}
          />
        </Row>

        <Row>
          <label htmlFor="showDashboardsTab" style={{ marginRight: "10px" }}>
            Show Dashboards Tab{" "}
          </label>
          <Radio
            toggle
            checked={this.state.showDashboardsTab}
            onChange={(_event, data) => {
              this.setState({ showDashboardsTab: data.checked || false });
            }}
          />
        </Row>

        <Row>
          <label htmlFor="showActionsTab" style={{ marginRight: "10px" }}>
            Show Actions Tab{" "}
          </label>
          <Radio
            toggle
            checked={this.state.showActionsTab}
            onChange={(_event, data) => {
              this.setState({ showActionsTab: data.checked || false });
            }}
          />
        </Row>

        <Row>
          <Input labelPosition="left" style={{ width: "350px" }}>
            <Label>Home Page</Label>
            <Dropdown
              search
              selection
              fluid
              options={homepageOptionsToShow}
              value={this.state.roleHomepage}
              onChange={(_event, data) => {
                if (data.value) {
                  this.setState({ roleHomepage: data.value });
                }
              }}
            />
          </Input>
        </Row>
      </React.Fragment>
    );
  }
}

type DeviceFiltersProps = {
  deviceFiltersError: { error: boolean; message: string };
  filters: { [key: string]: string[] | "all" };
  filterOptions: { [key: string]: string[] };
};

type DeviceFilter = {
  key: string;
  values: string[] | "all";
};

type FilterKeyOption = {
  key: string;
  text: string;
  value: string;
};

type DeviceFiltersState = {
  filters: DeviceFilter[];
  filterKeyOptions: FilterKeyOption[];
};

class DeviceFilters extends React.Component<
  DeviceFiltersProps,
  DeviceFiltersState
> {
  constructor(props: DeviceFiltersProps) {
    super(props);
    this.state = {
      filters: Object.keys(props.filters).map((key) => {
        return {
          key: key,
          values: props.filters[key],
        };
      }),
      filterKeyOptions: Object.keys(this.props.filterOptions).map(
        (filterKey) => {
          return {
            key: filterKey,
            text: filterKey,
            value: filterKey,
          };
        }
      ),
    };
  }

  updateFilter(index: number, filter: DeviceFilter) {
    this.setState((prevState) => {
      const updatedFilters = prevState.filters.map((fltr, idx) => {
        if (index === idx) {
          if (
            filter.values === "all" ||
            (Array.isArray(filter.values) && filter.values.includes("all"))
          ) {
            return { key: filter.key, values: "all" } as DeviceFilter;
          } else {
            return {
              key: filter.key,
              values: filter.values.filter((col) => col !== "all"),
            } as DeviceFilter;
          }
        }
        return fltr;
      });

      return { filters: updatedFilters };
    });
  }

  addFilter(filter) {
    this.setState((prevState) => {
      return { filters: [...prevState.filters, filter] };
    });
  }

  removeFilter(index) {
    this.setState((prevState) => {
      return {
        filters: prevState.filters.filter((_, idx) => idx !== index),
      };
    });
  }

  addFilterKeyOption(index: number) {
    // add element to the dropdown array again as field has been removed
    this.setState((prevState) => {
      return {
        filterKeyOptions: [
          {
            key: prevState.filters[index].key,
            text: prevState.filters[index].key,
            value: prevState.filters[index].key,
          },
          ...prevState.filterKeyOptions,
        ],
      };
    });
  }

  removeFilterKeyOption(value: string) {
    // remove selected item from the dropdown array in order to
    // not let it turn up again in the next dropdowns

    this.setState({
      filterKeyOptions: this.state.filterKeyOptions.filter(
        (option) => option.text !== value
      ),
    });
  }

  updateFilterKeyOptions(index: number, data: any) {
    // here when selected dropdown filter is changed,
    // existing selection needs to be added back to
    // the array and the newly selected one needs
    // to be removed in order to not let it turn up again in the dropdowns

    let newFilterKeyOptions = this.state.filterKeyOptions;

    newFilterKeyOptions = this.state.filterKeyOptions.filter(
      (option) => option.text !== data.value
    );

    this.setState({
      filterKeyOptions: [
        {
          key: this.state.filters[index].key,
          text: this.state.filters[index].key,
          value: this.state.filters[index].key,
        },
        ...newFilterKeyOptions,
      ],
    });
  }

  getDeviceFilters() {
    const filters = {};

    this.state.filters.forEach((filter) => {
      if (
        filter.values &&
        filter.values.length > 0 &&
        filter.values[0] === "all"
      ) {
        return (filters[filter.key] = "all");
      }
      return (filters[filter.key] = filter.values);
    });

    return filters;
  }

  // for fetching dropdown options
  componentDidUpdate(prevProps: Readonly<DeviceFiltersProps>): void {
    if (prevProps.filterOptions !== this.props.filterOptions) {
      this.setState({
        filterKeyOptions: Object.keys(this.props.filterOptions).map(
          (filterKey) => {
            return {
              key: filterKey,
              text: filterKey,
              value: filterKey,
            };
          }
        ),
      });
    }
  }

  render() {
    const props = this.props;
    const state = this.state;

    return (
      <React.Fragment>
        <HeaderWithPopup
          headerContent="Device Filters"
          popupContent="Enable or restrict access to devices for the user. Without specific metadata filters, all devices will be accessible by default."
        />

        {state.filters.map((filter, i) => {
          let filterValueOptions;

          let defaultFilterOptions = [
            {
              text: "All",
              key: "all",
              value: "all",
              onClick: (event: React.MouseEvent<HTMLDivElement>) => {
                event.preventDefault();

                this.updateFilter(i, { key: filter.key, values: "all" });
              },
            },
            {
              text: "divider",
              value: "divider",
              content: <DropdownDivider />,
              disabled: true,
            },
          ];

          if (filter.key === "id") {
            defaultFilterOptions = [];
          }

          // Checking if object is empty to avoid error for 'map of undefined'
          if (
            !_.isEmpty(props.filterOptions) &&
            props.filterOptions[filter.key]
          ) {
            filterValueOptions = [
              ...defaultFilterOptions,
              ...props.filterOptions?.[filter.key]?.map((o) => {
                return {
                  key: o,
                  text: o,
                  value: o + "",
                };
              }),
            ];
          } else {
            filterValueOptions = [...defaultFilterOptions];
          }

          filterValueOptions = filterValueOptions.filter(
            (option) => option.value !== ""
          );

          return (
            <Row key={`${i}`}>
              <EditRoleDropdown
                placeholder="Metadata Key"
                value={filter.key}
                fluid
                search
                selection
                options={[
                  {
                    key: filter.key,
                    text: filter.key,
                    value: filter.key,
                  },
                  ...this.state.filterKeyOptions,
                ]}
                onChange={(_, data) => {
                  this.updateFilterKeyOptions(i, data);
                  this.updateFilter(i, {
                    key: data.value as string,
                    values: [],
                  });
                }}
              />

              <DropDownErrorDivWrapper>
                <EditRoleDropdown
                  placeholder="Select Values"
                  options={filterValueOptions}
                  fluid
                  search
                  selection
                  multiple
                  value={filter.values === "all" ? ["all"] : filter.values}
                  onChange={(_event, data) => {
                    const { value } = data;
                    const isAllSelected = Array.isArray(value)
                      ? value.includes("all")
                      : value === "all";
                    const currentValues = this.state.filters[i].values;

                    let newValues: string[] | "all" = "all";
                    if (
                      isAllSelected &&
                      Array.isArray(value) &&
                      currentValues === "all"
                    ) {
                      newValues = (value as string[]).filter(
                        (v) => v !== "all"
                      );
                    } else if (isAllSelected) {
                      newValues = "all";
                    } else {
                      newValues = value as string[];
                    }

                    this.updateFilter(i, {
                      key: filter.key,
                      values: newValues,
                    });
                  }}
                />
                {props.deviceFiltersError.error &&
                  filter?.values?.length === 0 && (
                    <ErrorMessage style={{ top: "5px" }}>
                      {props.deviceFiltersError.message}
                    </ErrorMessage>
                  )}
              </DropDownErrorDivWrapper>

              <Button
                color="red"
                onClick={() => {
                  this.addFilterKeyOption(i);
                  this.removeFilter(i);
                }}
                icon
              >
                <Icon name="minus" />
              </Button>
            </Row>
          );
        })}

        <Row>
          <EditRoleDropdown
            placeholder="Select key to filter by"
            value={""}
            fluid
            search
            selection
            options={this.state.filterKeyOptions}
            onChange={(_, data) => {
              this.addFilter({ key: data.value as string, values: [] });
              this.removeFilterKeyOption(String(data.value));
            }}
          />
        </Row>
      </React.Fragment>
    );
  }
}

type Table = {
  name: string;
  columns: "all" | string[];
};

type DataAccessProps = {
  dataAccessError: { error: boolean; message: string };
  tables: { [key: string]: "all" | string[] };
  allTables: { [key: string]: string[] };
};

type DataAccessState = {
  tables: Table[];
};

class DataAccess extends React.Component<DataAccessProps, DataAccessState> {
  constructor(props: DataAccessProps) {
    super(props);
    this.state = {
      tables: Object.keys(props.tables).map((table) => {
        return {
          name: table,
          columns: props.tables[table],
        };
      }),
    };
  }

  updateTable(index: number, table: Table) {
    this.setState((prevState) => {
      const updatedTables = prevState.tables.map((tbl, idx) => {
        if (idx === index) {
          if (
            table.columns === "all" ||
            (Array.isArray(table.columns) && table.columns.includes("all"))
          ) {
            return { name: table.name, columns: "all" } as Table;
          } else {
            return {
              name: table.name,
              columns: table.columns.filter((col) => col !== "all"),
            } as Table;
          }
        }
        return tbl;
      });

      return { tables: updatedTables };
    });
  }

  addTable(table: Table) {
    this.setState({ tables: [...this.state.tables, table] });
  }

  removeable(index) {
    const tables = this.state.tables;

    this.setState({
      tables: [...tables.slice(0, index), ...tables.slice(index + 1)],
    });
  }

  getTables() {
    const tables = {};
    this.state.tables.forEach((table) => (tables[table.name] = table.columns));
    return tables;
  }

  getColumnOptions(table, index) {
    return [
      {
        text: "All",
        key: "all",
        value: "all",
        onClick: (event: React.MouseEvent<HTMLDivElement>) => {
          event.preventDefault();
          this.updateTable(index, { name: table.name, columns: "all" });
        },
      },
      {
        text: "divider",
        value: "divider",
        content: <DropdownDivider />,
        disabled: true,
      },
      ...(this.props.allTables[table.name] || [])
        .filter((field) => !field.startsWith("."))
        .map((field) => {
          return {
            key: field,
            text: field,
            value: field,
          };
        }),
    ];
  }

  addAllTables() {
    const allTables = Object.keys(this.props.allTables).map((table) => {
      return {
        name: table,
        columns: "all" as const,
      };
    });

    this.setState({ tables: allTables });
  }

  removeAllTables() {
    this.setState({ tables: [] });
  }

  render() {
    const props = this.props;
    const state = this.state;
    let options = Object.keys(props.allTables);
    options = options.filter((name) => {
      const found = state.tables.find((tb) => tb.name === name);
      if (found) {
        return false;
      }
      return true;
    });
    let tableOptions = options.map((table) => {
      return {
        key: table,
        text: table,
        value: table,
      };
    });
    return (
      <React.Fragment>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <HeaderWithPopup
            headerContent="Data Access"
            popupContent="Manage user's access to data streams and tables. Specify particular column access or select 'All' for full table visibility. Ensure proper access is set for a complete overview of all dashboard panel data."
          />
          {state.tables.length > 0 && (
            <AddAllColText
              style={{ marginBottom: "12px" }}
              onClick={() => this.removeAllTables()}
            >
              Clear all
            </AddAllColText>
          )}
        </div>
        {state.tables.map((table, i) => {
          const columnOptions = this.getColumnOptions(table, i);
          return (
            <Row key={table.name}>
              <EditRoleDropdown
                placeholder="Select Table"
                defaultValue={table.name}
                text={table.name}
                fluid
                search
                selection
                options={[
                  {
                    key: table.name,
                    text: table.name,
                    value: table.name,
                  },
                  ...tableOptions,
                ]}
                onChange={(_, data) => {
                  this.updateTable(i, {
                    name: data.value as string,
                    columns: [],
                  });
                }}
              />
              <DropDownErrorDivWrapper>
                <EditRoleDropdown
                  placeholder={"Select Columns"}
                  options={columnOptions}
                  fluid
                  search
                  selection
                  multiple
                  value={table.columns === "all" ? ["all"] : table.columns}
                  onChange={(_event, data) => {
                    const { value } = data;
                    const isAllSelected = Array.isArray(value)
                      ? value.includes("all")
                      : value === "all";
                    const currentColumns = this.state.tables[i].columns;

                    let newColumns: string[] | "all" = "all" as const;
                    if (
                      isAllSelected &&
                      Array.isArray(value) &&
                      currentColumns === "all"
                    ) {
                      newColumns = (value as string[]).filter(
                        (v) => v !== "all"
                      );
                    } else if (isAllSelected) {
                      newColumns = "all";
                    } else {
                      newColumns = value as string[];
                    }

                    this.updateTable(i, {
                      name: table.name,
                      columns: newColumns,
                    });
                  }}
                />
                {props.dataAccessError.error && table.columns.length === 0 && (
                  <ErrorMessage style={{ top: "5px" }}>
                    {props.dataAccessError.message}
                  </ErrorMessage>
                )}
              </DropDownErrorDivWrapper>
              <Button
                color="red"
                onClick={() => {
                  this.removeable(i);
                }}
                icon
              >
                <Icon name="minus" />
              </Button>
            </Row>
          );
        })}

        <Row
          style={{
            justifyContent: "space-between",
          }}
        >
          <EditRoleDropdown
            placeholder="Add Table"
            value={""}
            fluid
            search
            selection
            options={tableOptions}
            loading={
              tableOptions.length === 0 && this.state.tables?.length === 0
            }
            onChange={(_, data) => {
              this.addTable({ name: data.value as string, columns: [] });
            }}
          />
          {tableOptions.length > 0 && (
            <AddAllColText onClick={() => this.addAllTables()}>
              Add All Access
            </AddAllColText>
          )}
        </Row>
      </React.Fragment>
    );
  }
}

type MetadataAccessProps = {
  user: User;
  viewMetadata: string[] | "all";
  editMetadata: string[] | "all";
  viewMetadataKeys: boolean;
  editMetadataKeys: boolean;
  allMetadataKeys: string[];
};

type MetadataAccessState = {
  viewMetadata: string[] | "all";
  editMetadata: string[] | "all";
  viewMetadataKeys: boolean;
  editMetadataKeys: boolean;
};

class MetadataAccess extends React.Component<
  MetadataAccessProps,
  MetadataAccessState
> {
  constructor(props: MetadataAccessProps) {
    super(props);

    this.state = {
      viewMetadata: props.viewMetadata,
      editMetadata: props.editMetadata,
      viewMetadataKeys: props.viewMetadataKeys,
      editMetadataKeys: props.editMetadataKeys,
    };
  }

  getViewMetadata() {
    return this.state.viewMetadata;
  }

  getEditMetadata() {
    return this.state.editMetadata;
  }

  getViewMetadataKeys() {
    return this.state.viewMetadataKeys;
  }

  getEditMetadataKeys() {
    return this.state.editMetadataKeys;
  }

  getViewMetadataOptions() {
    return [
      {
        text: "All",
        key: "all",
        value: "all",
        onClick: (event: React.MouseEvent<HTMLDivElement>) => {
          event.preventDefault();

          this.setState({ viewMetadata: "all" });
        },
      },
      {
        text: "divider",
        value: "divider",
        content: <DropdownDivider />,
        disabled: true,
      },

      ...this.props.allMetadataKeys.map((o) => {
        return {
          key: o,
          text: capitalizeFirstLetter(o),
          value: o,
        };
      }),
    ];
  }

  getEditMetadataOptions() {
    return [
      {
        text: "All",
        key: "all",
        value: "all",
        onClick: (event: React.MouseEvent<HTMLDivElement>) => {
          event.preventDefault();

          this.setState({ editMetadata: "all" });
        },
      },
      {
        text: "divider",
        value: "divider",
        content: <DropdownDivider />,
        disabled: true,
      },

      ...this.props.allMetadataKeys.map((o) => {
        return {
          key: o,
          text: capitalizeFirstLetter(o),
          value: o,
        };
      }),
    ];
  }

  handleViewMetadataChange = (newValue: string[] | "all") => {
    const currentlyAll = this.state.viewMetadata === "all";
    let value: string[] | "all" = "all" as const;
    if (currentlyAll) {
      value = (newValue as string[]).filter((v) => v !== "all");
    } else if (
      (newValue as string[]).includes("all") ||
      newValue.includes("all")
    ) {
      value = "all" as const;
    } else {
      value = newValue as string[];
    }

    this.setState({
      viewMetadata: value,
    });
  };

  handleEditMetadataChange = (newValue: string[] | "all") => {
    const currentlyAll = this.state.editMetadata === "all";

    let newEditMetadata: string[] | "all" = "all" as const;
    let newViewMetadata: string[] | "all" =
      typeof this.state.viewMetadata !== "string"
        ? [...this.state.viewMetadata]
        : this.state.viewMetadata;

    if (currentlyAll) {
      newEditMetadata = (newValue as string[]).filter((v) => v !== "all");
    } else if (
      (newValue as string[]).includes("all") ||
      newValue.includes("all")
    ) {
      newEditMetadata = "all" as const;
    } else {
      newEditMetadata = newValue as string[];
    }

    // Append new items from editMetadata to viewMetadata if not already present
    if (newEditMetadata === "all") newViewMetadata = "all" as const;
    else {
      newEditMetadata.forEach((item) => {
        if (!newViewMetadata.includes(item) && newViewMetadata !== "all") {
          newViewMetadata.push(item);
        }
      });
    }

    this.setState({
      editMetadata: newEditMetadata,
      viewMetadata: newViewMetadata,
    });
  };

  render() {
    const serialMetadataKey =
      this.props.user["tenant-settings"]?.["serial-key"];
    return (
      <React.Fragment>
        <Row>
          <Input labelPosition="left">
            <Label>Allow viewing metadata</Label>
            <Dropdown
              style={{ minWidth: "300px" }}
              placeholder={"Select Metadata"}
              options={this.getViewMetadataOptions()}
              fluid
              search
              selection
              multiple
              value={
                this.state.viewMetadata === "all"
                  ? ["all"]
                  : this.state.viewMetadata
              }
              onChange={(_event, data) => {
                this.handleViewMetadataChange(data.value as string[]);
              }}
            />
          </Input>
        </Row>

        <Row>
          <Input labelPosition="left">
            <Label>Allow editing metadata</Label>
            <Dropdown
              style={{ minWidth: "300px" }}
              placeholder={"Select Metadata"}
              options={this.getEditMetadataOptions()}
              fluid
              search
              selection
              multiple
              value={
                this.state.editMetadata === "all"
                  ? ["all"]
                  : this.state.editMetadata
              }
              onChange={(_event, data) =>
                this.handleEditMetadataChange(data.value as string[])
              }
            />
          </Input>
        </Row>
        {serialMetadataKey && (
          <NoteWithPopup
            noteContent={
              <>
                <b>NOTE: {capitalizeFirstLetter(serialMetadataKey)}</b> is set
                as the serial-key
              </>
            }
            popupContent={
              "This metadata will be visible to all roles but cannot be edited in the metadata table until it is added to the allowed edit metadata fields."
            }
          />
        )}
      </React.Fragment>
    );
  }
}

type FirmwareUpdateProps = {
  viewFiles: boolean;
  editFiles: boolean;
  viewFirmwares: boolean;
  editFirmwares: boolean;
  viewDeviceConfigs: boolean;
  editDeviceConfigs: boolean;
};

type FirmwareUpdateState = FirmwareUpdateProps;

class FirmwareUpdate extends React.Component<
  FirmwareUpdateProps,
  FirmwareUpdateState
> {
  constructor(props) {
    super(props);

    this.state = props;
  }

  getState() {
    return this.state;
  }

  render() {
    return (
      <React.Fragment>
        <HeaderWithPopup
          headerContent="Inventory"
          popupContent="Permit users to access and handle firmware, files, and configuration versions."
        />
        <Row>
          <label style={{ paddingRight: "10px" }}>
            Allow user to view firmware versions{" "}
          </label>
          <Radio
            toggle
            checked={this.state.viewFirmwares}
            onChange={(_event, data) => {
              this.setState({ viewFirmwares: data.checked || false });
              this.setState({
                editFirmwares: data.checked ? this.state.editFirmwares : false,
              });
            }}
          />
        </Row>

        <Row
          style={{
            display: this.state.viewFirmwares ? "" : "none",
            paddingLeft: "10px",
          }}
        >
          <label style={{ paddingRight: "10px" }}>
            - Allow user to create, deactivate and activate firmware versions
          </label>
          <Radio
            toggle
            checked={this.state.editFirmwares}
            onChange={(_event, data) =>
              this.setState({ editFirmwares: data.checked || false })
            }
          />
        </Row>

        <Row>
          <label style={{ paddingRight: "10px" }}>
            Allow user to view files{" "}
          </label>
          <Radio
            toggle
            checked={this.state.viewFiles}
            onChange={(_event, data) => {
              this.setState({ viewFiles: data.checked || false });
              this.setState({
                editFiles: data.checked ? this.state.editFiles : false,
              });
            }}
          />
        </Row>

        <Row
          style={{
            display: this.state.viewFiles ? "" : "none",
            paddingLeft: "10px",
          }}
        >
          <label style={{ paddingRight: "10px" }}>
            - Allow user to create, deactivate and activate files
          </label>
          <Radio
            toggle
            checked={this.state.editFiles}
            onChange={(_event, data) =>
              this.setState({ editFiles: data.checked || false })
            }
          />
        </Row>

        <Row>
          <label style={{ paddingRight: "10px" }}>
            Allow user to view config versions{" "}
          </label>
          <Radio
            toggle
            checked={this.state.viewDeviceConfigs}
            onChange={(_event, data) => {
              this.setState({ viewDeviceConfigs: data.checked || false });
              this.setState({
                editDeviceConfigs: data.checked
                  ? this.state.editDeviceConfigs
                  : false,
              });
            }}
          />
        </Row>

        <Row
          style={{
            display: this.state.viewDeviceConfigs ? "" : "none",
            paddingLeft: "10px",
          }}
        >
          <label style={{ paddingRight: "10px" }}>
            - Allow user to create, edit and delete config versions{" "}
          </label>
          <Radio
            toggle
            checked={this.state.editDeviceConfigs}
            onChange={(_event, data) =>
              this.setState({ editDeviceConfigs: data.checked || false })
            }
          />
        </Row>
      </React.Fragment>
    );
  }
}

type DeviceManagementProps = {
  allowCreatingDevices: boolean;
};

type DeviceManagementState = DeviceManagementProps;

class DeviceManagement extends React.Component<
  DeviceManagementProps,
  DeviceManagementState
> {
  constructor(props) {
    super(props);
    this.state = props;
  }

  getState() {
    return this.state.allowCreatingDevices;
  }

  render() {
    return (
      <Row>
        <label htmlFor="allowCreatingDevices" style={{ paddingRight: "10px" }}>
          Allow creating new devices
        </label>
        <Radio
          toggle
          checked={this.state.allowCreatingDevices}
          onChange={(_event, data) =>
            this.setState({ allowCreatingDevices: data.checked || false })
          }
        />
      </Row>
    );
  }
}

type SettingsConfigProps = {
  editTenantSettings: boolean;
};

type SettingsConfigState = SettingsConfigProps;

class SettingsConfig extends React.Component<
  SettingsConfigProps,
  SettingsConfigState
> {
  constructor(props) {
    super(props);
    this.state = props;
  }

  getState() {
    return this.state;
  }

  render() {
    return (
      <React.Fragment>
        <HeaderWithPopup
          headerContent="Settings"
          popupContent="Give permissions to users for editing project-level settings, which include adding custom time ranges or pinning metadata in the device management section."
        />
        <Row>
          <label style={{ paddingRight: "10px" }}>
            Allow user to edit Project settings{" "}
          </label>
          <Radio
            toggle
            checked={this.state.editTenantSettings}
            onChange={(_event, data) =>
              this.setState({ editTenantSettings: data.checked || false })
            }
          />
        </Row>
      </React.Fragment>
    );
  }
}

type DashboardsProps = {
  createDashboards: boolean;
  permittedShareRoles: number[] | "all";
  allRoles: { id: number; name: string }[];
};

type DashboardsState = {
  permittedShareRoles: number[] | "all";
  createDashboards: boolean;
};

class Dashboards extends React.Component<DashboardsProps, DashboardsState> {
  constructor(props: DashboardsProps) {
    super(props);

    this.state = {
      permittedShareRoles: props.permittedShareRoles,
      createDashboards: props.createDashboards,
    };
  }

  updatePermittedShareRoles(roles: number[] | "all") {
    this.setState({ permittedShareRoles: roles });
  }

  getPermittedShareRoles() {
    return this.state.permittedShareRoles;
  }

  canCreateDashboards() {
    return this.state.createDashboards;
  }

  render() {
    const roleOptions = [
      {
        text: "All",
        key: "all",
        value: "all",
        onClick: (event: React.MouseEvent<HTMLDivElement>) => {
          event.preventDefault();

          this.updatePermittedShareRoles("all");
        },
      },
      {
        text: "divider",
        value: "divider",
        content: <DropdownDivider />,
        disabled: true,
      },

      ...this.props.allRoles.map((role) => {
        return {
          key: role.id,
          text: role.name,
          value: role.id,
        };
      }),
    ];

    return (
      <React.Fragment>
        <HeaderWithPopup
          headerContent="Dashboards"
          popupContent="Authorize users to create or alter dashboards. Determine their ability to share these dashboards externally."
        />
        <Row>
          <label htmlFor="createDashboards" style={{ paddingRight: "10px" }}>
            Allow user to create, edit and delete dashboards
          </label>
          <Radio
            toggle
            checked={this.state.createDashboards}
            onChange={(_event, data) =>
              this.setState({ createDashboards: data.checked || false })
            }
          />
        </Row>

        <Row>
          <Input labelPosition="left">
            <Label>Allow sharing dashboards with</Label>
            <Dropdown
              style={{ minWidth: "300px" }}
              placeholder={"Select Roles"}
              options={roleOptions}
              fluid
              search
              selection
              multiple
              value={
                this.state.permittedShareRoles === "all"
                  ? ["all"]
                  : this.state.permittedShareRoles
              }
              onChange={(_event, data) => {
                const { value } = data;
                const isAllSelected = Array.isArray(value)
                  ? value.includes("all")
                  : value === "all";

                let newValue: number[] | "all" = "all" as const;
                if (
                  isAllSelected &&
                  Array.isArray(value) &&
                  this.state.permittedShareRoles === "all"
                ) {
                  newValue = (value as string[])
                    .filter((v) => v !== "all")
                    .map(Number);
                } else if (isAllSelected) {
                  newValue = "all" as const;
                } else {
                  newValue = (value as string[])
                    .filter((v) => v !== "all")
                    .map(Number);
                }

                this.updatePermittedShareRoles(newValue);
              }}
            />
          </Input>
        </Row>
      </React.Fragment>
    );
  }
}

type ActionsProps = {
  allowedActions: string[] | "all";
  allActions: string[];
  viewActionTypes: boolean;
  editActionTypes: boolean;
  allowMarkActionAsCompleted: boolean;
};

type ActionsState = {
  allowedActions: string[] | "all";
  viewActionTypes: boolean;
  editActionTypes: boolean;
  allowMarkActionAsCompleted: boolean;
};

class Actions extends React.Component<ActionsProps, ActionsState> {
  constructor(props: ActionsProps) {
    super(props);

    this.state = {
      allowedActions: props.allowedActions,
      viewActionTypes: props.viewActionTypes,
      editActionTypes: props.editActionTypes,
      allowMarkActionAsCompleted: props.allowMarkActionAsCompleted,
    };
  }

  getAllowedActions() {
    return this.state.allowedActions;
  }

  getAllowMarkActionAsCompleted() {
    return this.state.allowMarkActionAsCompleted;
  }

  getState() {
    return this.state;
  }

  render() {
    const actionOptions = [
      {
        text: "All",
        key: "all",
        value: "all",
        onClick: (event: React.MouseEvent<HTMLDivElement>) => {
          event.preventDefault();

          this.setState({ allowedActions: "all" });
        },
      },
      {
        text: "divider",
        value: "divider",
        content: <DropdownDivider />,
        disabled: true,
      },

      ...this.props.allActions.map((action) => {
        return {
          key: action,
          text: capitalizeFirstLetter(action),
          value: action,
        };
      }),
    ];

    return (
      <React.Fragment>
        <HeaderWithPopup
          headerContent="Actions"
          popupContent="Allow users to execute Actions such as firmware and configuration updates. Give them access to mark these tasks as complete."
        />
        <Row>
          <Input labelPosition="left">
            <Label>Allow viewing/performing actions</Label>
            <Dropdown
              style={{ minWidth: "300px" }}
              placeholder={"Select Actions"}
              options={actionOptions}
              fluid
              search
              selection
              multiple
              value={
                this.state.allowedActions === "all"
                  ? ["all"]
                  : this.state.allowedActions
              }
              onChange={(_event, data) => {
                const { value } = data;
                const isAllSelected = Array.isArray(value)
                  ? value.includes("all")
                  : value === "all";

                let newValue: string[] | "all" = "all" as const;
                if (
                  isAllSelected &&
                  Array.isArray(value) &&
                  this.state.allowedActions === "all"
                ) {
                  newValue = (value as string[]).filter((v) => v !== "all");
                } else if (isAllSelected) {
                  newValue = "all" as const;
                } else {
                  newValue = value as string[];
                }

                this.setState({
                  allowedActions: newValue,
                });
              }}
            />
          </Input>
        </Row>
        <Row>
          <label style={{ paddingRight: "10px" }}>
            Allow marking action as completed
          </label>
          <Radio
            toggle
            checked={this.state.allowMarkActionAsCompleted}
            onChange={(_event, data) =>
              this.setState({
                allowMarkActionAsCompleted: data.checked || false,
              })
            }
          />
        </Row>
      </React.Fragment>
    );
  }
}

export type EditRoleModalProps = {
  isOpen: boolean;
  role?: Role;
  user: User;
  title: string;
  onSubmit: (role: Role) => void;
  onCancel: () => void;
  key?: string | number | null | undefined;
};

type EditRoleModalState = {
  deviceFilterOptions: { [key: string]: string[] };
  allTables: { [key: string]: string[] };
  allRoles: Role[];
  allRolesSet: Set<string>;
  activeTab: number | string;
  allActions: string[];
  allMetadataKeys: string[];
  allDashboards: DashboardAPIResponse[];
  role: Role;
  roleNameError: { error: boolean; message: string };
  deviceFiltersError: { error: boolean; message: string };
  dataAccessError: { error: boolean; message: string };
};

export class EditRoleModal extends React.Component<
  EditRoleModalProps,
  EditRoleModalState
> {
  roleConfigRef = React.createRef<GeneralConfig>();
  deviceFiltersRef = React.createRef<DeviceFilters>();
  dataAccessRef = React.createRef<DataAccess>();
  metadataAccessRef = React.createRef<MetadataAccess>();
  firmwareUpdateRef = React.createRef<FirmwareUpdate>();
  // streamConfigRef = React.createRef<StreamConfig>();
  settingsConfigRef = React.createRef<SettingsConfig>();
  dashboardsRef = React.createRef<Dashboards>();
  actionsRef = React.createRef<Actions>();
  deviceManagementRef = React.createRef<DeviceManagement>();
  constructor(props) {
    super(props);

    this.state = {
      deviceFilterOptions: {},
      allTables: {},
      allRoles: [],
      allRolesSet: new Set(),
      activeTab: 0,
      allActions: [],
      allMetadataKeys: [],
      allDashboards: [],
      role: props.role
        ? props.role
        : {
            name: "",
            id: 0,
            permissions: {
              devices: {},
              tables: {},
              viewFiles: false,
              editFiles: false,
              viewFirmwares: false,
              editFirmwares: false,
              viewDeviceConfigs: false,
              editDeviceConfigs: false,
              editMetadata: [],
              viewMetadata: [],
              editStreams: false,
              viewStreams: false,
              homepage: "/",
              dashboardPermittedShareRoles: [],
              createDashboards: false,
              allowedActions: [],
              showDashboardsTab: false,
              showDeviceManagementTab: false,
              showActionsTab: false,
              viewRoles: false,
              editRoles: false,
              viewUsers: false,
              editUsers: false,
              allowMarkActionAsCompleted: false,
              editActionTypes: false,
              viewActionTypes: false,
              allowCreatingDevices: false,
              viewMetadataKeys: false,
              editMetadataKeys: false,
              editTenantSettings: false,
            },
          },
      roleNameError: { error: false, message: "" },
      deviceFiltersError: { error: false, message: "" },
      dataAccessError: { error: false, message: "" },
    };
  }

  isValueColumnEmpty(columnValue: any): boolean {
    return (
      columnValue &&
      Object.values(columnValue).some((value: any) => {
        return Object.values(value as { [key: string]: any }).length < 1;
      })
    );
  }

  setActiveTab(tab: number | string) {
    this.setState({ activeTab: tab });
  }

  setRoleNameError(error, message) {
    this.setState({ roleNameError: { error, message } });
  }

  setDeviceFilterError(error, message) {
    this.setState({ deviceFiltersError: { error, message } });
  }

  setDataAccessError(error, message) {
    this.setState({ dataAccessError: { error, message } });
  }

  onSubmit() {
    const permissions = this.state.role.permissions;

    const roleNameRegex = /^[a-zA-Z][ ?(\w.)+]*$/g;

    const newPermissions: Partial<Permission> = {
      homepage: this.roleConfigRef.current?.getHomepage(),
      devices: this.deviceFiltersRef.current?.getDeviceFilters(),
      tables: this.dataAccessRef.current?.getTables(),

      viewFiles: this.firmwareUpdateRef.current?.getState().viewFiles ?? false,
      editFiles: this.firmwareUpdateRef.current?.getState().editFiles ?? false,

      viewFirmwares: this.firmwareUpdateRef.current?.getState().viewFirmwares,
      editFirmwares: this.firmwareUpdateRef.current?.getState().editFirmwares,

      viewDeviceConfigs:
        this.firmwareUpdateRef.current?.getState().viewDeviceConfigs,
      editDeviceConfigs:
        this.firmwareUpdateRef.current?.getState().editDeviceConfigs,

      // viewStreams: this.streamConfigRef.current?.getState().viewStreams,
      // editStreams: this.streamConfigRef.current?.getState().editStreams,
      viewStreams: true,
      editStreams: false,

      createDashboards: this.dashboardsRef.current?.canCreateDashboards(),
      dashboardPermittedShareRoles:
        this.dashboardsRef.current?.getPermittedShareRoles(),

      allowedActions: this.actionsRef.current?.getAllowedActions(),

      viewMetadata: this.metadataAccessRef.current?.getViewMetadata(),
      editMetadata: this.metadataAccessRef.current?.getEditMetadata(),

      showDeviceManagementTab:
        this.roleConfigRef.current?.shouldShowDeviceManagmentTab(),
      showDashboardsTab: this.roleConfigRef.current?.shouldShowDashboardsTab(),
      showActionsTab:
        this.roleConfigRef.current?.shouldShowActionsTab() ?? false,

      // viewUsers: this.rolesAndUsersRef.current?.getState().viewUsers,
      // editUsers: this.rolesAndUsersRef.current?.getState().editUsers,
      // viewRoles: this.rolesAndUsersRef.current?.getState().viewRoles,
      // editRoles: this.rolesAndUsersRef.current?.getState().editRoles,
      viewUsers: true,
      editUsers: false,
      viewRoles: true,
      editRoles: false,

      // viewActionTypes: this.actionsRef.current?.getState().viewActionTypes,
      // editActionTypes: this.actionsRef.current?.getState().editActionTypes,
      viewActionTypes: true,
      editActionTypes: false,

      allowCreatingDevices: this.deviceManagementRef.current?.getState(),
      allowMarkActionAsCompleted:
        this.actionsRef.current?.getAllowMarkActionAsCompleted(),

      viewMetadataKeys: true,
      editMetadataKeys: false,
      editTenantSettings:
        this.settingsConfigRef.current?.getState().editTenantSettings ?? false,
    };

    if (
      this.roleConfigRef.current?.shouldShowActionsTab() ||
      this.roleConfigRef.current?.shouldShowDashboardsTab()
    ) {
      newPermissions.viewMetadataKeys = true;
    }

    const updates = Object.fromEntries(
      Object.entries(newPermissions).filter(
        ([_, v]) => v !== null && v !== undefined
      )
    );
    const roleName = this.roleConfigRef.current?.getRoleName();
    const roleNameMatched = roleName?.match(roleNameRegex)?.length
      ? (roleName.match(roleNameRegex) as string[])
      : ([""] as string[]);

    const dataAccess = this.dataAccessRef.current?.getTables();
    const deviceFilters = this.deviceFiltersRef.current?.getDeviceFilters();

    if (roleName === "") {
      this.setRoleNameError(true, "Role Name cannot be empty");
      this.setActiveTab(0);
    } else if (roleNameMatched[0]?.length !== roleName?.length) {
      this.setRoleNameError(
        true,
        "Role Name should begin with character and only alphanumeric, space, underscore and dot are allowed."
      );
      this.setActiveTab(0);
    } else if (this.state.allRolesSet.has(roleName)) {
      this.setRoleNameError(true, "Role Name already exists");
      this.setActiveTab(0);
    } else if (this.isValueColumnEmpty(deviceFilters)) {
      this.setDeviceFilterError(true, "Device Filter values cannot be empty");
      this.setActiveTab(1);
    } else if (this.isValueColumnEmpty(dataAccess)) {
      this.setDataAccessError(true, "Column values cannot be empty");
      this.setActiveTab(2);
    } else {
      this.props.onSubmit({
        id: this.props.role ? this.props.role.id : 0,
        name: roleName?.trim(),
        permissions: Object.assign({}, permissions, updates),
      });
      this.setRoleNameError(false, "");
      this.setDeviceFilterError(false, "");
      this.setDataAccessError(false, "");
    }
  }

  async componentDidMount() {
    const p1 = fetchDeviceFilterOptions({});
    const p2 = fetchAllStreamsWithDetails();
    const p3 = fetchAllActionTypes();
    const p4 = fetchAllRoles(); // API Call can be removed if we get roles from props
    const p5 = fetchAllMetadataKeys();
    const p6 = fetchAllDashboards();

    const deviceFilters = await p1;
    const streams = await p2;
    const actions = await p3;
    const roles = await p4;
    const metadataKeys = await p5;
    const dashboards = await p6;

    const tables: { [key: string]: string[] } = {};
    Object.keys(streams).forEach((stream) => {
      let correctStreamName = stream;
      return (tables[correctStreamName] = Object.keys(streams[stream].fields));
    });

    const filterOptions: { [key: string]: string[] } = {};

    deviceFilters.forEach(
      (filter) => (filterOptions[filter.filterName] = filter.filterValues)
    );

    const allActions = actions.map((a) => a.type);

    const allMetadataKeys = metadataKeys.map((o) => o.key);

    let roleSet = new Set(roles.map((role) => role.name));

    if (this.props.role?.name) {
      roleSet.delete(this.props.role.name); // Removed props passed role name from the set to avoid duplicate role name error on edit
    }

    this.setState({
      deviceFilterOptions: filterOptions,
      allTables: tables,
      allActions: allActions,
      allRoles: roles,
      allRolesSet: roleSet,
      allMetadataKeys: allMetadataKeys,
      allDashboards: dashboards,
    });
  }

  render() {
    const props = this.props;

    const role: Role = this.state.role;

    const paneConfig = [
      {
        menuItem: "General",
        content: (
          <GeneralConfig
            name={role.name}
            user={this.props.user}
            roleNameError={this.state.roleNameError}
            homepage={role.permissions.homepage}
            showDeviceManagementTab={role.permissions.showDeviceManagementTab}
            showDashboardsTab={role.permissions.showDashboardsTab}
            showActionsTab={role.permissions.showActionsTab}
            dashboards={this.state.allDashboards}
            ref={this.roleConfigRef}
          />
        ),
      },
      {
        menuItem: "Device Permissions",
        content: (
          <DeviceFilters
            filters={role.permissions.devices}
            deviceFiltersError={this.state.deviceFiltersError}
            filterOptions={this.state.deviceFilterOptions}
            ref={this.deviceFiltersRef}
          />
        ),
      },
      {
        menuItem: "Data Access",
        content: (
          <DataAccess
            dataAccessError={this.state.dataAccessError}
            tables={role.permissions.tables}
            allTables={this.state.allTables}
            ref={this.dataAccessRef}
          />
        ),
      },
      {
        menuItem: "Device Management",
        content: (
          <React.Fragment>
            <HeaderWithPopup
              headerContent="Device Management"
              popupContent="Allow users to add or modify devices, including control over viewing and editing associated metadata."
            />
            <DeviceManagement
              allowCreatingDevices={role.permissions.allowCreatingDevices}
              ref={this.deviceManagementRef}
            />
            <MetadataAccess
              user={this.props.user}
              viewMetadata={role.permissions.viewMetadata}
              editMetadata={role.permissions.editMetadata}
              viewMetadataKeys={role.permissions.viewMetadataKeys}
              editMetadataKeys={role.permissions.editMetadataKeys}
              allMetadataKeys={this.state.allMetadataKeys}
              ref={this.metadataAccessRef}
            />
          </React.Fragment>
        ),
      },
      {
        menuItem: "Dashboards",
        content: (
          <Dashboards
            createDashboards={role.permissions.createDashboards}
            permittedShareRoles={role.permissions.dashboardPermittedShareRoles}
            allRoles={this.state.allRoles}
            ref={this.dashboardsRef}
          />
        ),
      },
      {
        menuItem: "Actions",
        content: (
          <React.Fragment>
            <Actions
              allowedActions={role.permissions.allowedActions}
              viewActionTypes={role.permissions.viewActionTypes}
              editActionTypes={role.permissions.editActionTypes}
              allActions={this.state.allActions}
              allowMarkActionAsCompleted={
                role.permissions.allowMarkActionAsCompleted
              }
              ref={this.actionsRef}
            />
            <FirmwareUpdate
              viewFiles={role.permissions.viewFiles}
              editFiles={role.permissions.editFiles}
              viewDeviceConfigs={role.permissions.viewDeviceConfigs}
              editDeviceConfigs={role.permissions.editDeviceConfigs}
              viewFirmwares={role.permissions.viewFirmwares}
              editFirmwares={role.permissions.editFirmwares}
              ref={this.firmwareUpdateRef}
            />
          </React.Fragment>
        ),
      },
      {
        menuItem: "Settings",
        content: (
          <SettingsConfig
            editTenantSettings={role.permissions.editTenantSettings}
            ref={this.settingsConfigRef}
          />
        ),
      },
    ];

    const panes = paneConfig.map((c, i) => {
      const onNext =
        i === paneConfig.length - 1
          ? undefined
          : () => this.setActiveTab(i + 1);

      return {
        menuItem: c.menuItem,
        pane: {
          as: BorderlessPane,
          key: `tab-${i}-${c.menuItem}`,
          active: true,
          content: <SettingsPane onNext={onNext}>{c.content}</SettingsPane>,
        },
      };
    });

    return (
      <Modal
        open={props.isOpen}
        size="large"
        className="dark"
        closeOnEscape
        closeOnTriggerClick
      >
        <Modal.Header> {props.title} </Modal.Header>

        <Modal.Content>
          <Tab
            menu={{
              fluid: true,
              vertical: true,
              tabular: true,
              borderless: true,
            }}
            activeIndex={this.state.activeTab}
            onTabChange={(_, data) => {
              this.setActiveTab(data.activeIndex ?? this.state.activeTab);
            }}
            panes={panes}
            renderActiveOnly={false}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button secondary onClick={() => this.props.onCancel()}>
            Cancel
          </Button>

          <Button primary onClick={() => this.onSubmit()}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
