import styled from "styled-components";
import { ActionStatusType } from "../../../BytebeamClient";
import { Icon } from "semantic-ui-react";
import { FilteredValue } from "./ActionsV3/ActionFilterDropdown";

export type ActionFilterOptions = {
  [filterName: string]: string[] | number[];
};

export interface NestedDropdownOptions {
  label: string;
  children?:
    | { text: string | number; value: string | number }[]
    | NestedDropdownOptions[];
}

export type ButtonPosition = {
  top: number;
  left: number;
  height: number;
  width: number;
};

export enum OptionType {
  NoOptionSelected,
  ChooseConfig,
  UploadConfig,
  ChooseFirmware,
  UploadFirmware,
  SendFile,
  UploadCommonConfig,
  NoPayloadOption,
  UploadText,
  SendScript,
  UploadGeofence,
  ChooseGeofence,
}

export function getObjectDepth(obj: any): number {
  let depth = 0;

  function calculateDepth(object: any, currentDepth: number): void {
    if (typeof object === "object" && object !== null) {
      for (const key in object) {
        if (object.hasOwnProperty(key)) {
          calculateDepth(object[key], currentDepth + 1);
        }
      }
    } else {
      depth = Math.max(depth, currentDepth);
    }
  }

  calculateDepth(obj, 0);

  return depth;
}

export function extractEmail(s: string): string | null {
  const regex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
  const match = s.match(regex);
  return match ? match[0] : null;
}

export const checkLogs = (key: string) => {
  return (
    key.toLowerCase().includes("log") &&
    !key.toLowerCase().includes("uplink_") &&
    !key.toLowerCase().includes("_local")
  );
};

export const OperationsButtonIcon = styled(Icon)`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text};
`;

/**
 * Returns true if the action is in Failed state, false otherwise.
 * @param action The action to check.

 */
export const isActionFailed = (action: ActionStatusType) => {
  const numTotal: number = Object.values(action.statuses).reduce(
    (a: number, b: number) => a + b,
    0
  );
  const { Failed } = action.statuses;
  if ((Failed || 0) === numTotal) {
    return true;
  } else {
    return false;
  }
};

export const isActionCompletedOrFailed = (action: ActionStatusType) => {
  const numTotal: number = Object.values(action.statuses).reduce(
    (a: number, b: number) => a + b,
    0
  );
  const { Completed, Failed } = action.statuses;
  if ((Completed || 0) + (Failed || 0) === numTotal) {
    return true;
  } else {
    return false;
  }
};

/**
 * Converts a decimal numeric value to its Roman numeral representation.
 *
 * This function takes a number and returns its equivalent in Roman numerals as a string.
 * If the input is not a number, it returns false. The conversion handles numbers up to 3999,
 * as traditional Roman numerals do not support higher values in a standard way.
 *
 * @param {number} num - The numeric value to convert to Roman numerals.
 * @returns {string | false} The Roman numeral representation of the given number as a string,
 * or false if the input is not a valid number.
 */
export function convertDecimalToRoman(num: number): string | false {
  if (typeof num !== "number") return false;

  const digits = String(num).split("");
  // prettier-ignore
  const key = [
    // "" 100 200 300 400 500 600 700 800 900
    "", "C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM",
    // "" 10 20 30 40 50 60 70 80 90
    "", "X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC",
    // "" 1 2 3 4 5 6 7 8 9
    "", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX",
  ];
  let roman = "";
  let i = 3;

  while (i--) roman = (key[+digits.pop()! + i * 10] || "") + roman;
  return "M".repeat(+digits.join("")) + roman;
}

/**
 * Converts a Roman numeral string to its decimal (numeric) representation.
 *
 * This function iterates through each character of the Roman numeral string from right to left,
 * converting each Roman numeral character to its decimal value and accumulating the result.
 * If a Roman numeral of lower value precedes one of higher value, it subtracts that value
 * (e.g., IV is 4, IX is 9). This function supports the standard Roman numerals up to 3999.
 *
 * Note: The function does not validate the input string for correct Roman numeral formatting.
 *
 * @param {string} roman - The Roman numeral string to convert to a decimal number.
 * @returns {number} The decimal representation of the Roman numeral string.
 */
export function convertRomanToDecimal(roman: string): number {
  const romanNumerals: { [key: string]: number } = {
    I: 1,
    V: 5,
    X: 10,
    L: 50,
    C: 100,
    D: 500,
    M: 1000,
  };
  let decimal = 0;
  let prevValue = 0;

  for (let i = roman.length - 1; i >= 0; i--) {
    const currentValue = romanNumerals[roman.charAt(i)];
    decimal += currentValue >= prevValue ? currentValue : -currentValue;
    prevValue = currentValue;
  }

  return decimal;
}

/**
 * Returns the index of the selected phase in the phases array.
 * @param selectedPhase The selected phase.
 */
export const getSelectedPhaseIndex = (selectedPhase: string) => {
  let selectedPhaseParts = selectedPhase.split(" ");
  const romanPart = selectedPhaseParts[selectedPhaseParts?.length - 1];
  const decimalPhase = convertRomanToDecimal(romanPart);
  return decimalPhase - 1;
};

export function convertPhaseToNumber(phase: string): number | "all" {
  if (phase.toLowerCase() === "all") {
    return "all";
  }

  const phasePattern = /^Phase ([IVXLCDM]+)$/i;
  const matches = phase.match(phasePattern);

  if (matches?.[1]) {
    const roman = matches[1].toUpperCase();
    return convertRomanToDecimal(roman);
  } else {
    throw new Error(
      "Invalid phase format. Please provide a phase in the format 'Phase [Roman Numeral]'"
    );
  }
}

export function condenseArraysBySum(arrays: number[][]): number[] {
  // Initialize the condensed array
  const condensedArray: number[] = [];

  // Iterate over each position in the arrays
  for (let i = 0; i < arrays[0]?.length; i++) {
    // Initialize the sum for this position
    let positionSum = 0;

    // Iterate over each array and sum the elements at the current position
    for (const array of arrays) {
      positionSum += array[i];
    }

    // Append the sum to the condensed array
    condensedArray.push(positionSum);
  }

  return condensedArray;
}

export const deviceStagesList: FilteredValue[] = [
  {
    id: 1,
    name: "Pending Approval",
    color: "progress_bar-pending_approval",
  },
  {
    id: 2,
    name: "Scheduled",
    color: "progress_bar-scheduled",
  },
  {
    id: 3,
    name: "Queued",
    color: "progress_bar-queued",
  },
  {
    id: 4,
    name: "Initiated",
    color: "progress_bar-initiated",
  },
  {
    id: 5,
    name: "In Progress",
    color: "progress_bar-in_progress",
  },
  {
    id: 6,
    name: "Completed",
    color: "progress_bar-completed",
  },
  {
    id: 7,
    name: "Failed",
    color: "progress_bar-failed",
  },
];

export const inProgressStatuses = [
  "ShellSpawned",
  "in_progress",
  "Progress",
  "Waiting%20for%20user",
  "Waiting%20for%20Bluetooth",
  "Downloaded",
  "Received",
  "Extracting%20payload",
  "Finalizing%20update",
  "Installing",
  "Installed",
  "Downloading",
];

// SemVer Versioning Validation for Firmware
export const validateFirmwareVersion = (version: string) => {
  const semanticVersionRegex = new RegExp(/^\d+\.\d+\.\d+(-\w+)?$/);
  return semanticVersionRegex.test(version);
};
