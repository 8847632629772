import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Icon, Modal } from "semantic-ui-react";
import { useHistory, useRouteMatch } from "react-router-dom";
import _ from "lodash";

import {
  Filters,
  PendingDeviceActionsResponse,
  getPendingActionsOnDevices,
  triggerDeviceAction,
  createFirmwareBundle,
  fetchAllActionTypes,
} from "../../../../BytebeamClient";
import { OptionType } from "../util";
import { validateTimestampInterval } from "../../util";
import { beamtoast } from "../../../common/CustomToast";
import { Mixpanel } from "../../common/MixPanel";

import Toggle from "../../../common/Toggle";
import LoadingAnimation from "../../../common/Loader";
import { StyledHeader } from "../ActionsV3/SelectableItem";
import { CardContainer } from "../../../common/ActionsUtils";

import SummaryComponent from "./SummaryComponent";
import PhaseControlSection from "./PhaseControlSection";
import CreateActionSection from "./CreateActionSection";
import AdvanceSettingsSection from "./AdvanceSettingsSection";
import ActionWarningModal from "./ActionWarningModal";
import { useUser } from "../../../../context/User.context";
import { ErrorMessage } from "../../../common/ErrorMessage";
import { MessageContainer } from "../../DeviceManagement/Devices/ActionModals/BulkMetadataUpdateModal";
import { ActionType } from "../../../../util";

export type PhaseInfo = {
  type: "fixed-list" | "filter-fraction-lazy" | "filter-fraction";
  device_ids?: number[];
  filter?: Record<string, string[]>;
  fraction?: number;
};

export type TimestampTriggerType = {
  timestamp: Date | number;
  failure_percentage?: number;
};

export type SuccessRateTriggerType = {
  success_percentage: number;
  failure_percentage?: number;
};

type TriggerOn = TimestampTriggerType | SuccessRateTriggerType;

export type PhaseData = {
  id: number;
  name: string;
  trigger_on: TriggerOn;
  info: PhaseInfo;
  status?: string;
  icon?: any;
};

export type ScheduleData = {
  retry_on_failure_until: number | Date;
  release_notes: string;
  end_timestamp: Date | number;
  phases: Omit<PhaseData, "id">[];
};

export type ActionRequestBody = {
  action: string;
  search_type: "default" | "all" | "allSearch";
  params: Record<string, any> | string;
  schedule: ScheduleData;
  device_ids?: string[];
  metadata?: Record<string, string[]>;
};

export const CreateActionContainer = styled.div<{ marginBottom?: string }>`
  display: flex;
  width: 100%;
  margin-bottom: 16px;
`;

export const NewActionLabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const NewActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledNonBoldHeader = styled(StyledHeader)`
  display: flex;
  align-items: center;
  margin-top: 0px;
  font-size: 18px;
  font-weight: normal;
  white-space: nowrap;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledButtons = styled(Button)`
  width: 300px;
  white-space: nowrap;
`;

enum TriggeredActionStatus {
  not_triggered = "Not Triggered",
  creating_bundle = "Creating Bundle",
  bundle_created = "Bundle Created",
  bundle_creation_failed = "Bundle Creation Failed",
  triggering_action = "Triggering Action",
  triggered = "Triggered",
  failed = "Failed",
}

type TriggeredActionStatusModalProps = {
  readonly isOpenActionStatusModal: boolean;
  readonly setIsOpenActionStatusModal: (value: boolean) => void;
  readonly triggeredActionStatus: string;
};

function TriggeredActionStatusModal(props: TriggeredActionStatusModalProps) {
  const {
    isOpenActionStatusModal,
    setIsOpenActionStatusModal,
    triggeredActionStatus,
  } = props;

  function handleClose() {
    setIsOpenActionStatusModal(false);
  }

  function getTriggeredActionStatusContent() {
    switch (triggeredActionStatus) {
      case TriggeredActionStatus.not_triggered:
        return "Action not triggered yet...";
      case TriggeredActionStatus.creating_bundle:
        return "Creating firmware bundle...";
      case TriggeredActionStatus.bundle_created:
        return "Firmware bundle created...";
      case TriggeredActionStatus.bundle_creation_failed:
        return "Firmware bundle creation failed...";
      case TriggeredActionStatus.triggering_action:
        return "Triggering action...";
      case TriggeredActionStatus.triggered:
        return "Action triggered successfully...";
      case TriggeredActionStatus.failed:
        return "Action triggering failed...";
      default:
        return "Action not triggered yet...";
    }
  }

  return (
    <Modal
      open={isOpenActionStatusModal}
      onClose={handleClose}
      size="mini"
      className="dark"
      closeIcon
    >
      <Modal.Header>Triggered Action Status</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <MessageContainer>
            {(triggeredActionStatus === TriggeredActionStatus.not_triggered ||
              triggeredActionStatus === TriggeredActionStatus.creating_bundle ||
              triggeredActionStatus === TriggeredActionStatus.bundle_created ||
              triggeredActionStatus ===
                TriggeredActionStatus.triggering_action) && (
              <LoadingAnimation
                loadingText={getTriggeredActionStatusContent()}
                loaderBorderSize="3px"
                loaderSize="30px"
                fontSize="16px"
                marginTopLoader="24px"
              />
            )}
            {(triggeredActionStatus ===
              TriggeredActionStatus.bundle_creation_failed ||
              triggeredActionStatus === TriggeredActionStatus.failed) && (
              <>
                <ErrorMessage
                  errorMessage
                  marginTop="24px"
                  marginBottom="0px"
                  iconSize="large"
                  messageLess
                />
                <p style={{ marginTop: "12px" }}>
                  {getTriggeredActionStatusContent()}
                </p>
              </>
            )}
            {triggeredActionStatus === TriggeredActionStatus.triggered && (
              <>
                <Icon
                  name="check circle outline"
                  size="large"
                  style={{ fontSize: "30px", marginTop: "24px" }}
                />
                <p style={{ marginTop: "8px" }}>
                  {getTriggeredActionStatusContent()}
                </p>
              </>
            )}
          </MessageContainer>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={handleClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export const neverTimestamp = 4110201000000; // Thu Apr 01 2100 00:00:00 in milliseconds

export default function NewAction() {
  const { user } = useUser();
  const componentsEnabled =
    user?.["tenant-settings"]?.show_tabs?.device_components?.show ?? false;
  const allowedActions = user.role.permissions.allowedActions;

  const history = useHistory();
  const matchedPath = useRouteMatch("/projects/:tenant/actions");

  const [mainLoading, setMainLoading] = useState<boolean>(true);

  // ------------------------- States for action creation START ------------------------- //
  const [action, setAction] = useState<string>("");
  const [actionTypes, setActionTypes] = useState<ActionType[]>([]);
  const [optionType, setOptionType] = useState<OptionType>(
    OptionType.NoOptionSelected
  );
  const [payload, setPayload] = useState<Record<string, any> | string>("");
  const [selectedVersions, setSelectedVersions] = useState<
    { id: number; value: string; device_component?: string }[]
  >([{ id: 0, value: "" }]);
  const [bundleVersion, setBundleVersion] = useState<string>("");
  const [isWarningModalOpen, setIsWarningModalOpen] = useState<boolean>(false);
  // -------------------------- States for action creation END -------------------------- //

  // ------------------------- States for phased rollout START ------------------------- //
  const [isPhasedRollout, setIsPhasedRollout] = useState<boolean>(false);
  const [phasesData, setPhasesData] = useState<PhaseData[]>([
    {
      id: 1,
      name: "Phase I",
      trigger_on: {
        timestamp: new Date(), // Default is immediate action
      },
      info: {
        type: "fixed-list", // Other phase type are filter-fraction-lazy and filter-fraction
        device_ids: [],
      },
    },
  ]);
  const [retryUntil, setRetryUntil] = useState<Date | number>(0);
  const [releaseNotes, setReleaseNotes] = useState<string>("");
  const [endTimestamp, setEndTimestamp] = useState<Date | number>(
    neverTimestamp
  );
  // -------------------------- States for phased rollout END -------------------------- //

  // -------------------------- States for triggering Action ---------------------------- //
  const [isOpenActionStatusModal, setIsOpenActionStatusModal] =
    useState<boolean>(false);
  const [triggeredActionStatus, setTriggeredActionStatus] = useState<string>(
    TriggeredActionStatus.not_triggered
  );
  const [pendingActionsOnDevices, setPendingActionsOnDevices] =
    useState<PendingDeviceActionsResponse>({});
  const [pendingActionsOnDevicesLoading, setPendingActionsOnDevicesLoading] =
    useState<boolean>(false);
  const [shouldTriggerImmediately, setShouldTriggerImmediately] =
    useState<boolean>(true);
  // ------------------------- States for triggering Action END ------------------------- //

  const handlePhasedRolloutToggleClick = useCallback(() => {
    setPhasesData([phasesData[0]]);
    setIsPhasedRollout(!isPhasedRollout);
  }, [isPhasedRollout, phasesData]);

  const isDeviceSelectedInPhases = (phase: Omit<PhaseData, "id">) => {
    const { type, device_ids } = phase.info;

    if (type === "fixed-list" && device_ids && device_ids.length === 0) {
      beamtoast.error(
        `No devices selected${isPhasedRollout ? " for phase: " + phase.name : ""}`
      );
      return false;
    }

    return true;
  };

  const isDeviceSelectedBool = (body: ActionRequestBody) => {
    return body?.schedule?.phases.every((phase) =>
      isDeviceSelectedInPhases(phase)
    );
  };

  const getNeverTimestamp = (
    actionType: string,
    firstPhaseTriggerTime: number
  ): number => {
    let timeout: number;

    let actionTypeType =
      actionTypes.find((at) => at?.type === actionType)?.timeout ??
      Number.MAX_VALUE;
    let globalTimeout =
      user["tenant-settings"]?.default_action_timeout ??
      30 * 24 * 60 * 60 * 1000; // milliseconds in a month;

    // Priority: actionTypeType > globalTimeout  -->  global Timeout default is 30 days
    if (actionTypeType !== Number.MAX_VALUE) {
      timeout =
        new Date(firstPhaseTriggerTime).getTime() + (actionTypeType as number);
    } else {
      timeout =
        new Date(firstPhaseTriggerTime).getTime() + (globalTimeout as number);
    }

    return timeout;
  };

  const createScheduleObject = (): ScheduleData => {
    const scheduleObject: ScheduleData = {
      retry_on_failure_until: retryUntil,
      release_notes: releaseNotes,
      end_timestamp: endTimestamp,
      phases: phasesData,
    };

    scheduleObject.phases = phasesData.map((phase) => {
      const deepCopiedPhase: PhaseData = _.cloneDeep(phase);
      const { id, ...restPhaseData } = deepCopiedPhase;

      let phaseInfo = { ...restPhaseData.info };

      if (phaseInfo.type === "fixed-list") {
        restPhaseData.info = {
          type: phaseInfo.type,
          // @ts-ignore
          device_ids: phaseInfo?.device_ids
            ?.toString()
            .split(",")
            .filter(Boolean),
        };
      } else if (
        phaseInfo.type === "filter-fraction-lazy" ||
        phaseInfo.type === "filter-fraction"
      ) {
        restPhaseData.info = {
          type: phaseInfo.type,
          filter: phaseInfo.filter,
          fraction: phaseInfo.fraction,
        };
      }

      if ((restPhaseData.trigger_on as TimestampTriggerType).timestamp) {
        const triggerOn = restPhaseData.trigger_on as TimestampTriggerType;

        // Handling changing the timestamp to current time for immediate action
        if (restPhaseData.name === "Phase I" && shouldTriggerImmediately) {
          (restPhaseData.trigger_on as TimestampTriggerType).timestamp =
            new Date();
        }

        (restPhaseData.trigger_on as TimestampTriggerType).timestamp =
          triggerOn.timestamp instanceof Date
            ? triggerOn.timestamp.getTime()
            : new Date(triggerOn.timestamp).getTime();
      }

      return restPhaseData;
    });

    // Setting end timestamp and retry until timestamp relative to the timestamp of the first phase
    const firstPhaseTimestamp = (
      scheduleObject.phases[0].trigger_on as TimestampTriggerType
    ).timestamp;

    scheduleObject.end_timestamp =
      endTimestamp === neverTimestamp
        ? getNeverTimestamp(action, new Date(firstPhaseTimestamp).getTime())
        : new Date(firstPhaseTimestamp).getTime() +
          (scheduleObject.end_timestamp as number);

    scheduleObject.retry_on_failure_until =
      retryUntil === 0 ? 0 : scheduleObject.end_timestamp;

    return scheduleObject;
  };

  const createRequestBody = (
    actionType: string,
    params: Record<string, any> | string
  ): ActionRequestBody => {
    const requestBody: ActionRequestBody = {
      action: actionType,
      params,
      schedule: createScheduleObject(),
      search_type: "default",
    };

    return requestBody;
  };

  // Validate the sum of fractions for each filter in phases
  const fractionSumValidation = () => {
    const filterFractions = {};
    const errorPhases = {};
    phasesData.forEach((element) => {
      if (
        element.info.type === "fixed-list" ||
        element.info.filter === undefined
      ) {
        return;
      }
      // sort the filter keys and values and making it array before stringify
      const sortedFilter = Object.entries(element.info.filter)
        .map(([key, value]) => [key, value.slice().sort()])
        .sort();
      const filterValue = JSON.stringify(sortedFilter);
      const fractionValue = element.info.fraction;

      // Update the sum of fractions for each filter
      if (filterFractions.hasOwnProperty(filterValue)) {
        filterFractions[filterValue] += fractionValue;
        errorPhases[filterValue].push(element.name);
      } else {
        filterFractions[filterValue] = fractionValue;
        errorPhases[filterValue] = [element.name];
      }
    });

    for (const filter in filterFractions) {
      if (filterFractions[filter] > 100) {
        return { result: false, phases: errorPhases[filter] }; // Return the filter for which the sum is exceeding 100
      }
    }

    return { result: true, phases: null };
  };

  async function areTherePendingActions(): Promise<boolean> {
    let pendingActionData: PendingDeviceActionsResponse = {};
    setPendingActionsOnDevicesLoading(true);

    async function getPendingActionInfo(filter: Filters) {
      const response = await getPendingActionsOnDevices(filter);
      pendingActionData = { ...pendingActionData, ...response };
    }

    try {
      for (const phase of phasesData) {
        if (phase.info.type === "fixed-list") {
          await getPendingActionInfo({
            id: phase.info.device_ids?.map(String) ?? [],
          });
        } else {
          await getPendingActionInfo(phase.info.filter as Filters);
        }
      }

      setPendingActionsOnDevices(pendingActionData);
      return (
        Object.values(pendingActionData)
          .flat()
          .filter(
            (action) =>
              action.type !== "cancel_action" && action.type !== "launch_shell"
          ).length > 0
      );
    } catch (error) {
      console.log("Error in fetching pending actions", error);
      return false;
    } finally {
      setPendingActionsOnDevicesLoading(false);
    }
  }

  // Validate if the action is to be auto-retry on failure and abort if the failure percentage is reached is used together
  const checkAbortAndAutoRetry = (schedule: ScheduleData) =>
    schedule.retry_on_failure_until &&
    schedule.phases.some((phase) => "failure_percentage" in phase.trigger_on);

  const validateFirmwareBundle = () => {
    if (
      selectedVersions.length !==
      new Set(selectedVersions.map((version) => version.device_component)).size
    ) {
      beamtoast.error("Please select device component only once");
      return false;
    } else if (
      selectedVersions.some(
        (fw) => fw.device_component === "" || fw.value === ""
      )
    ) {
      beamtoast.error("Please fill all the fields to create a bundle");
      return;
    } else if (
      selectedVersions.filter((version) => version.value === "").length
    ) {
      beamtoast.error(
        "Please select a firmware version for all device components"
      );
      return false;
    } else {
      return true;
    }
  };

  const triggerAction = async (
    actionType: string,
    params: Record<string, any> | string = {},
    checkPendingActions: boolean = true
  ) => {
    const allValidIntervals = phasesData.every((phase) =>
      validateTimestampInterval(
        (phase.trigger_on as TimestampTriggerType).timestamp,
        phase.name,
        phasesData,
        shouldTriggerImmediately
      )
    );
    const firstPhaseTriggerTime =
      phasesData.length > 1 &&
      (phasesData[0].trigger_on as TimestampTriggerType).timestamp;

    const body = createRequestBody(actionType, params);

    if (!isDeviceSelectedBool(body)) {
      return; // Validation message is been triggered through isDeviceSelectedBool function
    } else if (!allValidIntervals) {
      if (
        (phasesData.length > 1 && firstPhaseTriggerTime < new Date()) ||
        phasesData.length === 1
      ) {
        beamtoast.error(
          `The trigger time should be a minimum of 5 minutes later than the current time.`
        );
      } else if (phasesData.length > 1)
        beamtoast.error(
          `The time gap between consecutive phase's trigger time, should be a minimum of 5 minutes.`
        );
    } else if (!fractionSumValidation().result) {
      beamtoast.error(
        `The sum of fraction in ${
          fractionSumValidation().phases ?? " same filters in phases "
        } should not exceed 100%.`
      );
    } else if (checkAbortAndAutoRetry(body.schedule)) {
      beamtoast.error(
        `Auto-retry on failure and abort phase cannot be used together`
      );
    } else {
      let areTherePendingActionsOnDevices = false;
      if (checkPendingActions) {
        areTherePendingActionsOnDevices = await areTherePendingActions();
      }

      if (areTherePendingActionsOnDevices && checkPendingActions) {
        setIsWarningModalOpen(true);
      } else if (
        actionType === "update_firmware" &&
        componentsEnabled &&
        (selectedVersions[0].device_component !== "update_firmware with bundle"
          ? !validateFirmwareBundle()
          : false)
      ) {
        return; // Validation message is been triggered through validateFirmwareBundle function
      } else {
        try {
          if (
            actionType === "update_firmware" &&
            componentsEnabled &&
            selectedVersions[0].device_component !==
              "update_firmware with bundle"
          ) {
            setIsOpenActionStatusModal(true);
            setTriggeredActionStatus(TriggeredActionStatus.creating_bundle);

            const firmwareList = selectedVersions.map((version) => ({
              component_name: version.device_component as string,
              version_number: version.value,
            }));
            const createFirmwareBundlesRes = await createFirmwareBundle(
              firmwareList,
              bundleVersion
            );
            if (!createFirmwareBundlesRes) {
              beamtoast.error("Error in creating firmware bundle");
              setTriggeredActionStatus(
                TriggeredActionStatus.bundle_creation_failed
              );
              return;
            } else {
              body.params = {
                "bundle-id": createFirmwareBundlesRes.id,
              };
              setTriggeredActionStatus(TriggeredActionStatus.bundle_created);
            }
          }

          const res = await triggerDeviceAction(body);
          setTriggeredActionStatus(TriggeredActionStatus.triggering_action);

          if (res) {
            setMainLoading(true);
            setTriggeredActionStatus(TriggeredActionStatus.triggered);
            beamtoast.success(`${actionType} triggered successfully!`);

            Mixpanel.track("Triggered Action", { action: actionType });
            setTimeout(() => {
              setIsOpenActionStatusModal(false);
              history.push(`${matchedPath?.url}/live-actions`);
            }, 500);
          }
        } catch (error: any) {
          setTriggeredActionStatus(TriggeredActionStatus.failed);
          const errorRes = error.response;
          if (errorRes?.status === 400) {
            beamtoast.error(errorRes?.data);
          } else {
            beamtoast.error(`Error in triggering action: ${actionType}`);
            Mixpanel.track("Failure", {
              type: `trigger action ${actionType}`,
              error: JSON.stringify(error),
            });
          }
        } finally {
          setMainLoading(false);
        }
      }
    }
  };

  const executeAction = async (checkPendingActions: boolean = true) => {
    if (action === "") {
      beamtoast.error(`Please select an action to trigger`);
    } else {
      switch (optionType) {
        // Update Firmware Action
        case OptionType.ChooseFirmware:
        case OptionType.UploadFirmware:
          if (selectedVersions[0].value !== "")
            triggerAction(
              "update_firmware",
              componentsEnabled &&
                selectedVersions[0].device_component ===
                  "update_firmware with bundle"
                ? { "bundle-id": selectedVersions[0].value }
                : { version: selectedVersions[0].value },
              checkPendingActions
            );
          else
            beamtoast.error(
              `Please ${optionType === OptionType.ChooseFirmware ? "select" : "upload"} a firmware version`
            );
          break;

        // Update Config Action
        case OptionType.ChooseConfig:
        case OptionType.UploadConfig:
          if (selectedVersions[0].value !== "")
            triggerAction(
              "update_config",
              { version: selectedVersions[0].value },
              checkPendingActions
            );
          else
            beamtoast.error(
              `Please ${optionType === OptionType.ChooseConfig ? "select" : "create"} a JSON config version`
            );
          break;

        // Update Geofence Action
        case OptionType.ChooseGeofence:
        case OptionType.UploadGeofence:
          if (selectedVersions[0].value !== "")
            triggerAction(
              "update_geofence",
              { version: selectedVersions[0].value },
              checkPendingActions
            );
          else
            beamtoast.error(
              `Please ${optionType === OptionType.ChooseGeofence ? "select" : "create"} a GeoFence config version`
            );
          break;

        // Send File and Script Action
        case OptionType.SendFile:
        case OptionType.SendScript:
          if (selectedVersions[0].value !== "")
            triggerAction(
              optionType === OptionType.SendFile ? "send_file" : "send_script",
              { id: selectedVersions[0].value },
              checkPendingActions
            );
          else
            beamtoast.error(
              `Please upload ${optionType === OptionType.SendFile ? "file" : "script"}`
            );
          break;

        // Custom JSON and Text Action
        case OptionType.UploadCommonConfig:
        case OptionType.UploadText:
          if (payload !== "") {
            triggerAction(action, payload, checkPendingActions);
          } else
            beamtoast.error(
              `Please upload ${optionType === OptionType.UploadCommonConfig ? "JSON payload" : "text payload"}`
            );
          break;

        // No Payload Action
        case OptionType.NoPayloadOption:
          triggerAction(action, {}, checkPendingActions);
          break;

        default:
          break;
      }
    }
  };

  // show page is loading until user is not fetched
  useEffect(() => {
    if (user.email !== "") setMainLoading(false);
    else setMainLoading(true);
  }, [user]);

  useEffect(() => {
    async function getActionTypes() {
      try {
        const res = await fetchAllActionTypes();
        setActionTypes(res);
      } catch (e) {
        console.log(e);
      }
    }

    getActionTypes();
  }, []);

  if (mainLoading) {
    return (
      <LoadingAnimation
        loaderContainerHeight="80vh"
        loadingText="Loading..."
        fontSize="20px"
        loaderSize="48px"
      />
    );
  }

  if (allowedActions.length === 0) {
    return (
      <div
        style={{
          height: "65vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ErrorMessage
          errorMessage
          marginTop="30px"
          message="You do not have permissions to trigger Actions."
        />
      </div>
    );
  }

  return (
    <>
      <ActionWarningModal
        isWarningModalOpen={isWarningModalOpen}
        setIsWarningModalOpen={setIsWarningModalOpen}
        pendingActionsOnDevices={pendingActionsOnDevices}
        triggerAction={executeAction}
      />
      <TriggeredActionStatusModal
        isOpenActionStatusModal={isOpenActionStatusModal}
        setIsOpenActionStatusModal={setIsOpenActionStatusModal}
        triggeredActionStatus={triggeredActionStatus}
      />
      <CreateActionSection
        actionTypes={actionTypes}
        setAction={setAction}
        optionType={optionType}
        setOptionType={setOptionType}
        selectedVersions={selectedVersions}
        setSelectedVersions={setSelectedVersions}
        payload={payload}
        setPayload={setPayload}
        bundleVersion={bundleVersion}
        setBundleVersion={setBundleVersion}
      />
      <CreateActionContainer style={{ marginBottom: "2px" }}>
        <CardContainer>
          <NewActionWrapper>
            <StyledHeader
              as="h2"
              style={{ marginTop: "0px", marginBottom: "30px" }}
            >
              Select Devices
            </StyledHeader>
            <div style={{ position: "relative", paddingLeft: "15px" }}>
              <NewActionLabelContainer style={{ marginBottom: "20px" }}>
                <StyledNonBoldHeader as="h3" style={{ marginBottom: "15px" }}>
                  Phased Rollout
                </StyledNonBoldHeader>
                <div style={{ marginLeft: "40px", paddingBottom: "5px" }}>
                  <Toggle
                    id="action-toggle"
                    size="large"
                    bgcolor="#05DB0A"
                    checked={isPhasedRollout}
                    onChange={handlePhasedRolloutToggleClick}
                  />
                </div>
              </NewActionLabelContainer>
              <PhaseControlSection
                phasesData={phasesData}
                setPhasesData={setPhasesData}
                isPhasedRollout={isPhasedRollout}
                setIsPhasedRollout={setIsPhasedRollout}
                shouldTriggerImmediately={shouldTriggerImmediately}
                setShouldTriggerImmediately={setShouldTriggerImmediately}
              />
            </div>
          </NewActionWrapper>
        </CardContainer>
      </CreateActionContainer>
      <CreateActionContainer style={{ marginTop: "5px" }}>
        <CardContainer>
          <AdvanceSettingsSection
            endTimestamp={endTimestamp}
            releaseNotes={releaseNotes}
            action={action}
            setEndTimestamp={setEndTimestamp}
            setReleaseNotes={setReleaseNotes}
            setRetryUntil={setRetryUntil}
          />
        </CardContainer>
      </CreateActionContainer>
      <CreateActionContainer>
        <CardContainer>
          <SummaryComponent
            phasesData={phasesData}
            isPhasedRollout={isPhasedRollout}
            action={action}
            optionType={optionType}
            selectedVersion={selectedVersions[0].value}
            shouldTriggerImmediately={shouldTriggerImmediately}
          />
        </CardContainer>
      </CreateActionContainer>
      <ButtonsContainer>
        <StyledButtons
          primary
          size="big"
          loading={pendingActionsOnDevicesLoading}
          onClick={() => {
            executeAction();
          }}
        >
          Trigger Action
        </StyledButtons>
      </ButtonsContainer>
    </>
  );
}
