import React, {
  useState,
  useEffect,
  useRef,
  FormEvent,
  useCallback,
} from "react";
import {
  Icon,
  Button,
  Form,
  Modal,
  Input,
  Dropdown,
  Popup,
} from "semantic-ui-react";
import {
  uploadFile,
  FirmwareType,
  editFirmware,
} from "../../../../../BytebeamClient";
import { Mixpanel } from "../../../common/MixPanel";
import AnimatedEllipsis from "../../../common/AnimatedEllipsis";
import {
  StyledFileInput,
  StyledFileUploadButton,
} from "../../../DeviceManagement/Devices/ActionModals/SendFileModal";
import { beamtoast } from "../../../../common/CustomToast";
import { getHumanReadableFileSizeString } from "../../../util";
import {
  OptionType,
  //  validateFirmwareVersion
} from "../../util";

type CreateFirmwareModalProps = {
  readonly type: string;
  readonly selectedFirmwareVersion?: FirmwareType;
  readonly componentsEnabled?: boolean;
  readonly componentsList?: string[];
  readonly component: string;
  readonly isOpen: boolean;
  readonly close: () => void;
  readonly fillFirmwareTable?: (
    showDeactivatedFirmwares?: boolean
  ) => Promise<void>;
  readonly allExistingFirmwareVersions: FirmwareType[];

  readonly setOptionType?: (arg: OptionType) => void;
  readonly setSelectedVersion?: (version: string) => void;
  readonly setUploadedFileName?: (name: string) => void;
};

function CreateOrEditFirmwareModal(props: CreateFirmwareModalProps) {
  const firmwareFileInput = useRef<HTMLInputElement | null>(null);

  const [firmwareVersion, setFirmwareVersion] = useState<string>("");
  const [existingFirmwareVersions, setExistingFirmwareVersions] = useState<
    Set<string>
  >(new Set());

  const [uncompressedSize, setUncompressedSize] = useState<number | undefined>(
    undefined
  );
  const [previousUncompressedSize, setPreviousUncompressedSize] = useState<
    number | undefined
  >(undefined);
  const [unit, setUnit] = useState<string>("KiB");
  const [previouslySelectedUnit, setPreviouslySelectedUnit] =
    useState<string>("KiB");

  const [firmwareFile, setFirmwareFile] = useState<File>(
    new File([""], "filename")
  );
  const [firmwareFileName, setFirmwareFileName] = useState<string>("");
  const [firmwareFileLoaded, setFirmwareFileLoaded] = useState<number>(0);
  const [firmwareFileTotal, setFirmwareFileTotal] = useState<number>(0);
  const [showFirmwareUploadProgress, setShowFirmwareUploadProgress] =
    useState<boolean>(false);
  const [showFirmwareEditProgress, setShowFirmwareEditProgress] =
    useState<boolean>(false);
  const [uploadedFirmwareFileResponse, setUploadedFirmwareFileResponse] =
    useState<{
      status: number;
      data: { id: string };
    }>({
      status: 0,
      data: { id: "" },
    });

  const [disableUploadButton, setDisableUploadButton] = useState<boolean>(true);
  const [errors, setErrors] = useState<{ versionNumber: string | null }>({
    versionNumber: "",
  });
  const [selectedComponent, setSelectedComponent] = useState<string>(
    props.component
  );

  const createOrEditFirmware = async () => {
    const createUrl = "/api/v1/firmwares";

    // const compressedExtensions = ["zip", "tar", "rar", "7z", "gz", "bz2"];
    // const fileExtension = file?.name.split(".")?.pop()?.toLowerCase() ?? "";

    let formData = new FormData();
    formData.append("version-number", firmwareVersion); // append in both cases

    if (props.type !== "Edit") {
      // file checking only when creating new firmware
      if (firmwareFile !== null) formData.append("file", firmwareFile);
      else {
        beamtoast.error("A firmware file must be selected!");
        return;
      }

      if (props.componentsEnabled) {
        if (selectedComponent !== "") {
          formData.append("component-name", selectedComponent);
        } else {
          beamtoast.error("Please select a device component!");
          return;
        }
      }
    }

    let uploadSize: string = "";

    if (uncompressedSize !== undefined) {
      if (uncompressedSize <= 0) {
        beamtoast.error("Uncompressed size must be greater than 0!");
        return;
      } else {
        let factor = 1;

        if (unit === "KiB") {
          factor = 1024;
        } else if (unit === "MiB") {
          factor = 1024 ** 2;
        } else if (unit === "GiB") {
          factor = 1024 ** 3;
        }

        // Calculate upload size
        uploadSize = String(Math.ceil(uncompressedSize * factor));

        // Append the calculated size
        formData.append("uncompressed-size", uploadSize);
      }
    }

    try {
      setDisableUploadButton(true);

      let res: any = null;

      if (props.type === "Edit") {
        setShowFirmwareEditProgress(true);
        await editFirmware(firmwareVersion, Number(uploadSize));

        beamtoast.success(
          `Updated firmware version ${props.selectedFirmwareVersion?.["version_number"]}`
        );
      } else {
        setShowFirmwareUploadProgress(true);

        res = await uploadFile(createUrl, formData, (p) => {
          // for loading progress
          setFirmwareFileLoaded(p.loaded);
          setFirmwareFileTotal(p.total);
        });

        setUploadedFirmwareFileResponse(res);

        if (res.status === 201) {
          if (
            props.setOptionType &&
            props.setSelectedVersion &&
            props.setUploadedFileName
          ) {
            props.setOptionType(OptionType.UploadFirmware);
            props.setSelectedVersion(res.data["version_number"]);
            props.setUploadedFileName(res.data["file_name"]);
          }

          beamtoast.success(
            "Created Firmware version " + res.data["version_number"]
          );

          Mixpanel.track("Uploaded Firmware", {
            Firmware: res.data["version_number"],
          });
        }
      }

      if (props.fillFirmwareTable) await props.fillFirmwareTable();
      onCloseModal();
    } catch (error) {
      if (props.type === "Edit") {
        console.log(error);
        beamtoast.error("Error updating firmware!");
      } else {
        Mixpanel.track("Failure", {
          type: "Upload Firmware",
        });
        // Handling Error for File Size exceeding limit from NGINX
        if (String(error).includes("413"))
          beamtoast.error("Upload failed due to size limit!");
        else beamtoast.error("Failed to create Firmware version :(");
      }

      onCloseModal();
    }
  };

  const handleSubmit = async (event: FormEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (props.type !== "Edit") {
      let versionNumber = firmwareVersion.trim();
      let nameValidation: boolean = false;
      let fileValidation: boolean = false;

      if (versionNumber.length === 0) {
        setErrors({
          versionNumber: "Please enter firmware version number",
        });
      } else if (existingFirmwareVersions.has(versionNumber)) {
        setErrors({
          versionNumber: "This firmware version already exists",
        });
      }
      // else if (!validateFirmwareVersion(versionNumber)) {
      //   setErrors({
      //     versionNumber:
      //       "Please enter a semantic version number, e.g. 1.0.0, 1.0.0-beta etc...",
      //   });
      // }
      else nameValidation = true;

      if (firmwareFileInput?.current?.files) {
        const fileCount = firmwareFileInput.current.files.length;
        if (fileCount === 0) {
          setTimeout(() =>
            beamtoast.error("A firmware file must be selected!")
          );
        }
        // file is valid
        else fileValidation = true;
      }

      if (nameValidation && fileValidation) {
        // complete form is valid
        await createOrEditFirmware();
      }
    } else {
      // fallback check in case submit button lets user go through with wrong validation
      if (
        (uncompressedSize === previousUncompressedSize &&
          unit === previouslySelectedUnit) ||
        (Number.isNaN(uncompressedSize) &&
          previousUncompressedSize === undefined)
      )
        beamtoast.error("No change in values detected!");
      // form is valid
      else await createOrEditFirmware();
    }
  };

  const onSelect = useCallback((e) => {
    setShowFirmwareUploadProgress(false);
    setFirmwareFileLoaded(0);
    setFirmwareFileTotal(0);

    if (e.target.files.length !== 0) {
      setFirmwareFile(e.target.files[0]);
      setFirmwareFileName(e.target.files[0].name);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Reset state and close modal
  function onCloseModal() {
    // Reset state
    setFirmwareVersion("");
    setFirmwareFile(new File([""], "filename"));
    setFirmwareFileName("");
    setErrors({
      versionNumber: null,
    });
    setFirmwareFileLoaded(0);
    setFirmwareFileTotal(0);
    setShowFirmwareUploadProgress(false);
    setDisableUploadButton(true);
    setUnit("KiB");
    setUncompressedSize(undefined);
    setSelectedComponent("");
    setUploadedFirmwareFileResponse({
      status: 0,
      data: { id: "" },
    });

    // Close Modal
    props.close();
  }

  useEffect(() => {
    const existingVersions: Set<string> = new Set();

    props.allExistingFirmwareVersions.forEach((firmware: FirmwareType) => {
      existingVersions.add(firmware.version_number);
    });
    setExistingFirmwareVersions(existingVersions);

    if (props.type === "Edit" && props.selectedFirmwareVersion) {
      setFirmwareVersion(props.selectedFirmwareVersion?.version_number);
      setFirmwareFileName(props.selectedFirmwareVersion.file_name);
      if (props.selectedFirmwareVersion.device_component_name)
        setSelectedComponent(
          props.selectedFirmwareVersion.device_component_name
        );

      const size = props.selectedFirmwareVersion?.uncompressed_size;

      if (size && props.isOpen) {
        const sizeData: string[] =
          getHumanReadableFileSizeString(size).split(" ");

        setUncompressedSize(Number(sizeData[0]));
        setPreviousUncompressedSize(Number(sizeData[0]));

        setUnit(sizeData[1]);
        setPreviouslySelectedUnit(sizeData[1]);
      }
    } else {
      setSelectedComponent(props.component);
    }
  }, [props.isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      (uncompressedSize === previousUncompressedSize &&
        unit === previouslySelectedUnit) ||
      (Number.isNaN(uncompressedSize) && previousUncompressedSize === undefined)
    )
      setDisableUploadButton(true);
    else setDisableUploadButton(false);
  }, [
    uncompressedSize,
    unit,
    previousUncompressedSize,
    previouslySelectedUnit,
  ]);

  useEffect(() => {
    if (
      firmwareVersion &&
      firmwareVersion.trim().length !== 0 &&
      firmwareFile.name.length !== 0
    ) {
      setDisableUploadButton(false);
    } else {
      setDisableUploadButton(true);
    }
  }, [firmwareVersion, firmwareFile, uncompressedSize]);

  return (
    <Modal
      open={props.isOpen}
      onClose={props.close}
      size="tiny"
      className="dark"
      closeOnDimmerClick={false}
      closeOnEscape={false}
    >
      <Modal.Header>
        {props.type === "Edit" ? "Edit" : "Create New"} Firmware Version
      </Modal.Header>

      <Modal.Content>
        <Form>
          {props.componentsEnabled && (
            <Form.Field>
              <label htmlFor="firmware_version_number">
                Select Device Component
              </label>
              <Dropdown
                placeholder="Device Component"
                id="firmware_component_selection"
                selection
                options={props.componentsList?.map((component) => ({
                  key: component,
                  value: component,
                  text: component,
                }))}
                disabled={props.type === "Edit"}
                value={selectedComponent}
                onChange={(e, { value }) => {
                  setSelectedComponent(value as string);
                }}
                style={{ minWidth: "100px" }}
              />
            </Form.Field>
          )}
          <Form.Field>
            <label htmlFor="firmware_version_number">
              Firmware Version Number{" "}
              {/* <Popup
                inverted
                trigger={
                  <Icon
                    name="question circle"
                    style={{ marginBottom: "2px", marginLeft: "2px" }}
                  />
                }
                content={
                  "Only unique semantic version number of the firmware are allowed For example, 1.0.0, 1.0.0-beta, etc."
                }
                position="top center"
              /> */}
            </label>
            <Form.Input
              id="firmware_version_number"
              autoFocus
              autoComplete="off"
              error={errors.versionNumber !== "" ? errors.versionNumber : null}
              placeholder="1.0.0, 1.0.0-beta, etc."
              value={firmwareVersion}
              disabled={props.type === "Edit"}
              onChange={(e) => {
                setFirmwareVersion(e.target.value);
                setErrors({
                  versionNumber: null,
                });
              }}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="upload_file">
              Upload File from the system:{" "}
              {props.type === "Edit" ? "(not allowed while editing)" : ""}
            </label>
            <div
              style={{
                position: "relative",
                marginTop: "12px",
                cursor: props.type === "Edit" ? "not-allowed" : "pointer",
              }}
            >
              <StyledFileUploadButton
                fluid
                content="Select File"
                labelPosition="left"
                icon="file"
                disabled={props.type === "Edit"}
              />
              <StyledFileInput
                id="upload_file"
                type="file"
                ref={firmwareFileInput}
                disabled={props.type === "Edit"}
                onChange={onSelect}
              />
            </div>
            <label htmlFor="file_name" style={{ marginTop: "12px" }}>
              File Chosen:{" "}
            </label>
            <span id="file_name">
              {firmwareFileName !== "" ? firmwareFileName : "No File Chosen"}
            </span>
          </Form.Field>
          {props?.componentsEnabled === false && (
            <Form.Field>
              <label
                htmlFor="firmware_uncompressed_size"
                style={{ display: "flex", gap: "8px" }}
              >
                Uncompressed File Size (Optional)
                <Popup
                  inverted
                  trigger={
                    <Icon
                      name="question circle"
                      style={{ marginBottom: "2px", marginLeft: "2px" }}
                    />
                  }
                  content={
                    "Estimated disk space needed to successfully uncompressed file on your device"
                  }
                  position="top center"
                />
              </label>
              <div
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  gap: "12px",
                  width: "100%",
                }}
              >
                <Input
                  min="0"
                  type="number"
                  id="firmware_uncompressed_size"
                  placeholder="value"
                  value={uncompressedSize}
                  onChange={(e) => {
                    const value =
                      unit === "B"
                        ? parseInt(e.target.value)
                        : parseFloat(e.target.value);
                    setUncompressedSize(value);
                  }}
                  step={unit === "B" ? "1" : "0.01"}
                  style={{ minWidth: "calc(100% - 124px)" }}
                />
                <Dropdown
                  id="firmware_uncompressed_size_unit"
                  selection
                  options={[
                    { key: "B", value: "B", text: "B" },
                    { key: "KiB", value: "KiB", text: "KiB" },
                    { key: "MiB", value: "MiB", text: "MiB" },
                    { key: "GiB", value: "GiB", text: "GiB" },
                  ]}
                  value={unit}
                  onChange={(e, { value }) => {
                    setUnit(value as string);
                  }}
                  style={{ minWidth: "100px" }}
                />
              </div>
            </Form.Field>
          )}
          {showFirmwareUploadProgress && (
            <Form.Field>
              <label htmlFor="file-progress">
                {uploadedFirmwareFileResponse.status === 0 ? (
                  <span>
                    File Uploading
                    <AnimatedEllipsis spacing={3} dotSize={"8px"} />
                  </span>
                ) : (
                  <span>File Uploaded</span>
                )}
              </label>
              <progress
                id="file-progress"
                max={firmwareFileTotal}
                value={firmwareFileLoaded}
              />
            </Form.Field>
          )}
          {showFirmwareEditProgress && (
            <Form.Field>
              <label>
                Editing Firmware File{" "}
                <AnimatedEllipsis spacing={3} dotSize={"8px"} />
              </label>
            </Form.Field>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          id="cancel_button"
          secondary
          onClick={() => {
            onCloseModal();
          }}
        >
          <Icon name="remove" /> Discard
        </Button>
        <Button
          id="submit_button"
          disabled={disableUploadButton}
          primary
          onClick={async (e) => {
            await handleSubmit(e);
          }}
        >
          <Icon name="checkmark" /> Upload
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default CreateOrEditFirmwareModal;
