import React, { useEffect, useMemo, useState } from "react";
import { Button, Input, Label, Modal, Table } from "semantic-ui-react";
import {
  createFirmwareBundle,
  FirmwareType,
} from "../../../../../BytebeamClient";
import { beamtoast } from "../../../../common/CustomToast";
import { EditAnimatedMetaDropdown } from "../../../Dashboards/Panel/util";
import { validateFirmwareVersion } from "../../util";

type ComponentWiseFirmwareRowProps = {
  readonly firmware: {
    id: number;
    component_name: string;
    version_number: string;
  };
  readonly loadingComponentList: boolean;
  readonly componentsList: string[];
  readonly firmwareVersions: FirmwareType[];
  readonly firmwaresList: {
    id: number;
    component_name: string;
    version_number: string;
  }[];
  readonly setFirmwaresList: (
    firmwareList: {
      id: number;
      component_name: string;
      version_number: string;
    }[]
  ) => void;
};

function ComponentWiseFirmwareRow(props: ComponentWiseFirmwareRowProps) {
  const {
    firmware,
    componentsList,
    firmwareVersions,
    firmwaresList,
    setFirmwaresList,
    loadingComponentList,
  } = props;

  const [firmwareVersionOptions, setFirmwareVersionOptions] = useState<
    { key: string; value: string; text: string }[]
  >([]);

  const componentOptions = useMemo(() => {
    const selectedComponents = firmwaresList.map((fw) => fw.component_name);
    const componentsWithFirmware = componentsList.filter((component) =>
      firmwareVersions.some((fw) => fw.device_component_name === component)
    );
    const componentsWithoutFirmware = componentsList.filter(
      (component) => !componentsWithFirmware.includes(component)
    );

    return [
      ...componentsWithFirmware.map((component) => ({
        key: component,
        value: component,
        text: component,
      })),
      ...componentsWithoutFirmware.map((component) => ({
        key: component,
        value: component,
        text: component,
        disabled: true,
        style: { cursor: "not-allowed" },
        title: "This component does not have a firmwares",
      })),
    ].filter(
      (component) =>
        !selectedComponents.includes(component.value) ||
        component.value === firmware.component_name
    );
  }, [
    componentsList,
    firmwareVersions,
    firmwaresList,
    firmware.component_name,
  ]);

  function handleAdd() {
    setFirmwaresList([
      ...firmwaresList,
      {
        id: firmwaresList.at(-1)!.id + 1,
        component_name: "",
        version_number: "",
      },
    ]);
  }

  function handleDelete() {
    setFirmwaresList(firmwaresList.filter((fw) => fw.id !== firmware.id));
  }

  function handleSelectComponent(e, data) {
    e.preventDefault();

    const newFirmwaresList = [...firmwaresList];
    newFirmwaresList.map((newFirmware) => {
      if (newFirmware.id === firmware.id) {
        newFirmware.component_name = data.value;
        newFirmware.version_number = "";
      }

      return newFirmware;
    });
    setFirmwaresList(newFirmwaresList);
  }

  function handleSelectedVersions(version: string) {
    const newFirmwaresList = [...firmwaresList];
    newFirmwaresList.map((newFirmware) => {
      if (newFirmware.id === firmware.id) {
        newFirmware.version_number = version;
      }

      return newFirmware;
    });
    setFirmwaresList(newFirmwaresList);
  }

  useEffect(() => {
    setFirmwareVersionOptions(
      firmwareVersions
        .filter((firmwareVersion) => {
          return (
            firmware.component_name === firmwareVersion.device_component_name
          );
        })
        .map((version) => ({
          key: version.version_number,
          value: version.version_number,
          text: version.version_number,
        }))
    );
  }, [firmware.component_name]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Table.Row>
      <Table.Cell width={7}>
        <EditAnimatedMetaDropdown
          placeholder="Select Component"
          search
          selection
          options={componentOptions}
          onChange={handleSelectComponent}
          value={firmware.component_name}
          loading={loadingComponentList}
          disabled={loadingComponentList}
          elementid={`select_component`}
          style={{
            marginBottom: "4px",
            marginTop: "4px",
          }}
        />
      </Table.Cell>

      <Table.Cell width={7}>
        <EditAnimatedMetaDropdown
          placeholder={"Select Firmware Version"}
          fluid
          search
          selection
          disabled={firmware.component_name === ""}
          options={firmwareVersionOptions}
          onChange={(e, data) => {
            e.preventDefault();
            handleSelectedVersions(data.value as string);
          }}
          value={firmware.version_number}
          elementid={`select_version`}
          style={{
            marginBottom: "4px",
            marginTop: "4px",
          }}
        />
      </Table.Cell>

      <Table.Cell width={3}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexWrap: "nowrap",
            gap: "8px",
          }}
        >
          {firmwaresList.length > 1 && (
            <Button onClick={handleDelete} icon="minus" secondary />
          )}
          {(firmwaresList.length === 1 ||
            firmware.id === firmwaresList.at(-1)?.id) &&
            firmwaresList.length < componentsList.length && (
              <Button
                onClick={handleAdd}
                icon="plus"
                primary
                disabled={
                  firmware.component_name === "" ||
                  firmware.version_number === ""
                }
              />
            )}
        </div>
      </Table.Cell>
    </Table.Row>
  );
}

type CreateFirmwareBundleModalProps = {
  readonly isOpen: boolean;
  readonly close: () => void;
  readonly componentsList: string[];
  readonly firmwareVersions: FirmwareType[];
  readonly fillFirmwareBundlesTable: () => void;
  readonly existingBundleVersions: Set<string>;
  readonly loadingComponentList: boolean;
};

function CreateFirmwareBundleModal(props: CreateFirmwareBundleModalProps) {
  const {
    isOpen,
    close,
    componentsList,
    firmwareVersions,
    fillFirmwareBundlesTable,
    existingBundleVersions,
    loadingComponentList,
  } = props;

  const [bundleVersion, setBundleVersion] = useState("");
  const [firmwaresList, setFirmwaresList] = useState<
    { id: number; component_name: string; version_number: string }[]
  >([{ id: 0, component_name: "", version_number: "" }]);

  function onModalClose() {
    // Reset State
    setBundleVersion("");
    setFirmwaresList([{ id: 0, component_name: "", version_number: "" }]);

    // Close Modal
    close();
  }

  async function handleCreateFirmwareBundle() {
    let version = bundleVersion.trim();
    if (version === "") {
      beamtoast.error("Please enter the firmware bundle version");
    } else if (!validateFirmwareVersion(version)) {
      beamtoast.error(
        "Please enter a semantic version number, e.g. 1.0.0, 1.0.0-beta etc..."
      );
    } else if (existingBundleVersions.has(version)) {
      beamtoast.error("This firmware bundle version already exists");
    } else if (firmwaresList.length === 0) {
      beamtoast.error("Please select at least 1 firmware to create a bundle");
    } else if (
      firmwaresList.length !==
      new Set(firmwaresList.map((version) => version.component_name)).size
    ) {
      beamtoast.error("Please select device component only once");
    } else if (
      firmwaresList.some(
        (fw) => fw.version_number === "" && fw.component_name !== ""
      )
    ) {
      let emptyFirmware = firmwaresList.find((fw) => fw.version_number === "");
      beamtoast.error(
        `Please select the firmware version for "${emptyFirmware?.component_name}" component`
      );
    } else {
      try {
        const firmwares = firmwaresList.flatMap((fw) =>
          fw.component_name === ""
            ? []
            : [
                {
                  component_name: fw.component_name,
                  version_number: fw.version_number,
                },
              ]
        );

        const res = await createFirmwareBundle(firmwares, version);
        if (res) {
          beamtoast.success("Firmware Bundle Created Successfully");
          fillFirmwareBundlesTable();
          onModalClose();
        }
      } catch (error) {
        beamtoast.error("Failed to Create Firmware Bundle");
        console.error("Failed to Create Firmware Bundle", error);
      }
    }
  }

  return (
    <Modal
      className="dark"
      onClose={() => onModalClose()}
      size="small"
      open={isOpen}
    >
      <Modal.Header>Create Firmware Bundle</Modal.Header>
      <Modal.Content>
        <div style={{ width: "100%" }}>
          <Input
            fluid
            labelPosition="left"
            placeholder="Enter the semantic bundle version e.g 1.0.0, 1.0.0-beta, etc."
          >
            <Label>Firmware Bundle Version</Label>
            <input
              autoFocus
              value={bundleVersion}
              onChange={(e) => setBundleVersion(e.target.value)}
            />
          </Input>
        </div>
        <Table fixed>
          <Table.Body>
            {firmwaresList.map((firmware) => (
              <ComponentWiseFirmwareRow
                key={firmware.id}
                firmware={firmware}
                componentsList={componentsList}
                firmwareVersions={firmwareVersions}
                firmwaresList={firmwaresList}
                setFirmwaresList={setFirmwaresList}
                loadingComponentList={loadingComponentList}
              />
            ))}
          </Table.Body>
        </Table>
      </Modal.Content>

      <Modal.Actions>
        <Button secondary onClick={() => onModalClose()} content="Cancel" />
        <Button content="Submit" onClick={handleCreateFirmwareBundle} primary />
      </Modal.Actions>
    </Modal>
  );
}

export default CreateFirmwareBundleModal;
