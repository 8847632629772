import React, {
  useState,
  useEffect,
  createRef,
  useCallback,
  useImperativeHandle,
  useRef,
  forwardRef,
} from "react";
import {
  Accordion,
  Button,
  Checkbox,
  Dropdown,
  Form,
  Grid,
  Icon,
  Input,
  Label,
  Modal,
} from "semantic-ui-react";
import styled from "styled-components";
import {
  StreamFields,
  fetchAllStreamsWithDetails,
  stopDBCParser,
  updateDBCSettings,
  uploadFile,
} from "../../../../BytebeamClient";
import {
  DBCAdditionalSettings,
  DBCData,
  DBCResponse,
  dropDownOptionsFromArray,
} from "../../../../util";
import _ from "lodash";
import { beamtoast } from "../../../common/CustomToast";
import { StyledFileUploadButton } from "../../DeviceManagement/Devices/ActionModals/SendFileModal";
import { StyledInput as StyledFileInput } from "../../Actions/action-modals/UploadFirmwareComponent";
import AnimatedEllipsis from "../../common/AnimatedEllipsis";
import { Mixpanel } from "../../common/MixPanel";
import LoadingAnimation from "../../../common/Loader";
import { DBCOperationType } from "./DBC";
import SimpleConfirmationModal from "../../common/SimpleConfirmationModal";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledLabel = styled(Label)`
  margin: 0px !important;
  min-width: 80px;
`;

export const StyledInput = styled(Input)`
  margin-bottom: 14px;
  height: 36px;
  width: 100%;
`;

type Stream = {
  [key: string]: StreamFields;
};

interface UploadDBCFileSectionProps {
  readonly onClose: () => void;
  readonly DBCData: DBCResponse[];
  readonly newDBC: DBCData;
  readonly selectedDBC?: DBCResponse;
  readonly validStreams: React.MutableRefObject<Stream>;
  readonly allStreams: React.MutableRefObject<string[]>;
  readonly operationType: string;
  readonly setLoading: (arg0: boolean) => void;
  setNewDBCName: (arg0: string) => void;
  handleUpdate: () => void;
}

interface handleUploadDBC {
  handleUpload(): void;
}

function areAllKeysNotEmpty(obj: Record<string, any>): boolean {
  return Object.entries(obj).every(([key, value]) => {
    if (key === "additional_settings") {
      return true; // Skip checking this key as it is not mandatory
    } else {
      return value || value === 0;
    }
  });
}

const UploadDBCFileSection = forwardRef<
  handleUploadDBC,
  UploadDBCFileSectionProps
>((props, ref) => {
  const DBCFileInput = createRef<any>();

  const [DBCFileName, setDBCFileName] = useState<string>("");
  const [DBCFile, setDBCFile] = useState<File>(new File([""], "filename"));
  const [showDBCUploadProgress, setShowDBCUploadProgress] =
    useState<boolean>(false);
  const [DBCFileLoaded, setDBCFileLoaded] = useState<number>(0);
  const [DBCFileTotal, setDBCFileTotal] = useState<number>(0);
  const [uploadedDBCFileResponse, setUploadedDBCFileResponse] = useState<{
    status: number;
    data: { id: string };
  }>({
    status: 0,
    data: { id: "" },
  });
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  const onSelect = useCallback((e) => {
    setShowDBCUploadProgress(false);
    setDBCFileLoaded(0);
    setDBCFileTotal(0);

    if (e.target.files.length !== 0) {
      const selectedFile = e.target.files[0];
      if (selectedFile) {
        const fileName = selectedFile.name;
        const fileExtension = fileName.split(".").pop(); // Get the file extension
        if (fileExtension === "dbc") {
          setDBCFile(selectedFile);
          setDBCFileName(fileName);
        } else {
          // File extension is not ".dbc", show an error message and handle it accordingly
          beamtoast.error(`Only ".dbc" file types are allowed!`);
          console.log(
            'Only files with the ".dbc" extension are allowed to be uploaded.'
          );
          setShowDBCUploadProgress(false);
          setDBCFileLoaded(0);
          setDBCFileTotal(0);
          setDBCFile(new File([""], "filename"));
          setDBCFileName("");
        }
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const uploadDBC = async (additional_settings: string) => {
    // DBC Upload API Endpoint
    const url = `/api/v1/dbcs`;

    let formData = new FormData();

    // need to keep this check here itself due to ts strict typechecking
    if (
      props.newDBC.name &&
      props.newDBC.version &&
      props.newDBC.input_table &&
      props.newDBC.output_table &&
      props.newDBC.period &&
      additional_settings
    ) {
      formData.append("file", DBCFile);
      formData.append("name", props.newDBC.name.trim());
      formData.append("version", props.newDBC.version.trim());
      formData.append("input_table", props.newDBC.input_table);
      formData.append("output_table", props.newDBC.output_table.trim());
      formData.append("period", props.newDBC.period.toString().trim());
      formData.append("additional_settings", additional_settings);
    } else {
      beamtoast.error("All fields are required.");
      return;
    }

    try {
      const res = await uploadFile(url, formData, (p) => {
        // for loading progress
        setDBCFileLoaded(p.loaded);
        setDBCFileTotal(p.total);
      });

      setUploadedDBCFileResponse(res);

      if (res.status === 201) {
        beamtoast.success(`Created DBC "${props.newDBC.name}"`);
        Mixpanel.track("Uploaded DBC", {
          Firmware: res.data["version"],
        });
        props.setNewDBCName(props.newDBC.name ? props.newDBC.name : "");
        props.onClose();
        props.handleUpdate();
      }
    } catch (error: any) {
      console.log(error);

      Mixpanel.track("Failure", {
        type: "Upload DBC",
      });
      props.setNewDBCName("");

      // Handling Error for File Size exceeding limit from NGINX
      if (String(error).includes("413")) {
        beamtoast.error("Upload failed due to size limit!");
      }

      // Handling other error types
      const errorMessage = error.response?.data?.error;

      if (errorMessage.includes("invalid continuation byte")) {
        beamtoast.error("Uploaded file is not supported!");
      } else if (errorMessage.includes("fixprotobuff failed")) {
        beamtoast.error("DBC created but fixprotobuff failed");
      } else {
        beamtoast.error("Failed to create DBC: ", errorMessage); // showing error here as suggested by Nikhil for better user error reporting
      }

      console.log(errorMessage); // print error message received from api error response
    }
  };

  const editDBC = useCallback(
    async (additional_settings: DBCAdditionalSettings) => {
      // need to keep this check here itself due to ts strict typechecking
      if (
        props.newDBC.name &&
        props.newDBC.version &&
        props.newDBC.input_table &&
        props.newDBC.output_table &&
        props.newDBC.period &&
        additional_settings
      ) {
        if (props.selectedDBC) {
          try {
            props.setLoading(true);
            await updateDBCSettings(props.selectedDBC.id, {
              name: props.newDBC.name.trim(),
              period: props.newDBC.period.toString().trim(),
              version: props.newDBC.version.trim(),
              additional_settings: {
                dbc_ver: additional_settings.dbc_ver ?? null,
                lower_threshold: additional_settings.lower_threshold ?? null,
                upper_threshold: additional_settings.upper_threshold ?? null,
              },
            });

            beamtoast.success(`Edited DBC "${props.newDBC.name}"`);
            Mixpanel.track("Edited DBC", {
              Firmware: props.newDBC.version,
            });
            props.setNewDBCName(props.newDBC.name ? props.newDBC.name : "");
            props.setLoading(false);
            props.onClose();
            props.handleUpdate();
          } catch (error: any) {
            props.setLoading(false);
            console.log(error);

            Mixpanel.track("Failure", {
              type: "Upload DBC",
            });
            props.setNewDBCName("");

            // Handling other error types
            const errorMessage = error.response?.data?.error;
            console.log(errorMessage); // print error message received from api error response
          }
        }
      } else {
        beamtoast.error("All fields are required.");
        return;
      }
    },
    [props.selectedDBC, props.newDBC] // eslint-disable-line react-hooks/exhaustive-deps
  );

  // stream validity
  // only allowed to contain underscore as special character without spaces
  const isStreamValid = (output_stream: string) => {
    const regex = /^(?=.*[a-zA-Z])[a-zA-Z0-9_]+$/;
    return regex.test(output_stream);
  };

  const isStreamUnique = (output_stream: string) => {
    if (props.operationType === DBCOperationType.Edit) return true;
    return !props.allStreams.current.find((stream) => stream === output_stream);
  };

  function isNumeric(input: string): boolean {
    // Regular expression to match numeric values
    const numericRegex = /^-?(?!-)\d*\.?\d+$/;

    // Test if the input matches the numeric regex
    return numericRegex.test(input);
  }

  // function for all data upload/edit validation
  const handleUpload = useCallback(() => {
    let fileValidation = props.operationType === DBCOperationType.Edit;
    let outputStreamValidation = false;
    let cycleTimeValidation = false;
    let additionalSettingsValidation = true;

    let lowerThresholdValidation = true;
    let upperThresholdValidation = true;
    let dbcVersionFilterValidation = true;

    // no file upload is happening while edit mode is on
    // file validation: cannot be empty + .dbc required
    if (props.operationType === DBCOperationType.Create) {
      if (DBCFileInput?.current?.files && DBCFileName !== "") {
        const fileCount = DBCFileInput.current.files.length;
        if (fileCount === 0) {
          setTimeout(() => beamtoast.error("A DBC file must be selected!"));
        } else {
          fileValidation = true;
        }
      } else {
        beamtoast.error("A DBC file must be selected!");
      }
    }

    // output_stream value validation acc to regex
    if (
      props.newDBC.output_table &&
      isStreamValid(props.newDBC.output_table) &&
      isStreamUnique(props.newDBC.output_table)
    )
      outputStreamValidation = true;
    else {
      outputStreamValidation = false;
      if (
        props.newDBC.output_table &&
        !isStreamUnique(props.newDBC.output_table)
      )
        beamtoast.error("Output stream name already exists!");
      else
        beamtoast.error(
          "Please enter a valid output stream name! Space and special characters are not allowed except underscore"
        );
    }

    // period validation (cannot be <= 0)
    if (props.newDBC.period && props.newDBC.period > 0)
      cycleTimeValidation = true;
    else {
      cycleTimeValidation = false;
      beamtoast.error("Cycle time cannot be negative or zero!");
    }

    let additional_settings = props.newDBC.additional_settings;

    // Helper function for error handling
    function showError(message: string) {
      lowerThresholdValidation = false;
      upperThresholdValidation = false;
      beamtoast.error(message);
    }

    // Initialize validation flags
    lowerThresholdValidation = true;
    upperThresholdValidation = true;

    // Check if additional_settings is defined
    if (additional_settings === undefined) {
      additional_settings = {};
    }

    // Handle undefined thresholds
    if (additional_settings.lower_threshold === undefined) {
      additional_settings.lower_threshold = null;
    }
    if (additional_settings.upper_threshold === undefined) {
      additional_settings.upper_threshold = null;
    }

    // Check if both thresholds are defined
    if (
      additional_settings.lower_threshold !== null &&
      additional_settings.upper_threshold !== null
    ) {
      const lower = additional_settings.lower_threshold;
      const upper = additional_settings.upper_threshold;

      // Validate numeric values
      if (isNumeric(lower.toString()) && isNumeric(upper.toString())) {
        if (lower >= upper) {
          showError(
            "Lower Threshold cannot be equal or greater than upper threshold!"
          );
        } else {
          lowerThresholdValidation = lower > 0;
          upperThresholdValidation = upper > 0;
          if (!lowerThresholdValidation)
            beamtoast.error("Lower Threshold cannot be negative or zero!");
          if (!upperThresholdValidation)
            beamtoast.error("Upper Threshold cannot be negative or zero!");
        }
      } else {
        if (!isNumeric(lower.toString())) {
          lowerThresholdValidation = false;
          beamtoast.error("Please enter a valid lower threshold value!");
        }
        if (!isNumeric(upper.toString())) {
          upperThresholdValidation = false;
          beamtoast.error("Please enter a valid upper threshold value!");
        }
      }
    } else {
      // Handle individual threshold validation
      if (additional_settings.lower_threshold !== null) {
        const lower = additional_settings.lower_threshold;
        if (isNumeric(lower.toString()) && lower > 0) {
          lowerThresholdValidation = true;
        } else {
          lowerThresholdValidation = false;
          beamtoast.error(
            isNumeric(lower.toString())
              ? "Lower Threshold cannot be negative or zero!"
              : "Please enter a valid lower threshold value!"
          );
        }
      } else delete additional_settings.lower_threshold;

      if (additional_settings.upper_threshold !== null) {
        const upper = additional_settings.upper_threshold;
        if (isNumeric(upper.toString()) && upper > 0) {
          upperThresholdValidation = true;
        } else {
          upperThresholdValidation = false;
          beamtoast.error(
            isNumeric(upper.toString())
              ? "Upper Threshold cannot be negative or zero!"
              : "Please enter a valid upper threshold value!"
          );
        }
      } else delete additional_settings.upper_threshold;
    }

    // Handle undefined value
    if (additional_settings.dbc_ver === undefined) {
      additional_settings.dbc_ver = null;
    }

    const isFieldPresentInStream = (field: string): boolean => {
      const input_table = props.newDBC.input_table;
      const validStreams = props.validStreams.current;

      return Object.keys(validStreams).some(
        (stream: string) =>
          stream === input_table && // get the selected stream &&
          // check if "dbc_ver" field is present in the stream
          Object.keys(validStreams[stream]).includes(field)
      );
    };

    /*
     * validation for dbc_ver:
     ** cannot be < 0
     ** "dbc_ver" field should be present in the selected stream
     */
    if (additional_settings?.dbc_ver !== null) {
      if (additional_settings?.dbc_ver >= 0) {
        if (isFieldPresentInStream("dbc_ver"))
          dbcVersionFilterValidation = true;
        else {
          dbcVersionFilterValidation = false;
          beamtoast.error(
            "cannot pass dbc_ver as its column is not present in the selected stream!"
          );
        }
      } else {
        dbcVersionFilterValidation = false;
        beamtoast.error("Version Filter cannot be negative!");
      }
    } else delete additional_settings.dbc_ver;

    // cumulative validation check
    if (
      lowerThresholdValidation &&
      upperThresholdValidation &&
      dbcVersionFilterValidation
    )
      additionalSettingsValidation = true;
    else additionalSettingsValidation = false;

    if (
      fileValidation &&
      outputStreamValidation &&
      cycleTimeValidation &&
      additionalSettingsValidation
    ) {
      if (!additional_settings) additional_settings = {};

      setShowDBCUploadProgress(true);

      // check type of mode to trigger corresponding api
      if (props.operationType === DBCOperationType.Create)
        uploadDBC(JSON.stringify(additional_settings));
      else if (props.operationType === DBCOperationType.Edit) {
        // check if parser is already running and restart
        // only if cycle time or any additional settings have been changed
        const { selectedDBC, newDBC } = props;
        const isRunning = selectedDBC?.status === "started";
        const isPeriodDifferent = selectedDBC?.period !== newDBC?.period;

        const prevAdditionalSettings = selectedDBC?.additional_settings ?? {};
        const updatedAdditionalSettings = newDBC?.additional_settings ?? {};

        // Check for extra keys in updated data with non-undefined and non-false values
        const hasExtraKeys = Object.keys(updatedAdditionalSettings).some(
          (key) =>
            !prevAdditionalSettings.hasOwnProperty(key) &&
            updatedAdditionalSettings[key] !== null &&
            updatedAdditionalSettings[key] !== false
        );

        let areAdditionalSettingsEqual = true;

        if (!hasExtraKeys) {
          // Check if all common keys in both objects have equal values
          areAdditionalSettingsEqual = Object.keys(
            prevAdditionalSettings
          ).every(
            (key) =>
              updatedAdditionalSettings.hasOwnProperty(key) &&
              prevAdditionalSettings[key] === updatedAdditionalSettings[key]
          );
        } else {
          areAdditionalSettingsEqual = false;
        }

        // check for any overall updates else do not trigger the update-api without any need
        const {
          additional_settings: as1,
          id,
          tenant_id,
          status,
          offsets,
          ...prevRest
        } = props.selectedDBC ?? {};
        const { additional_settings: as2, ...updatedRest } = props.newDBC;

        if (areAdditionalSettingsEqual && _.isEqual(prevRest, updatedRest)) {
          beamtoast.error("No values have been updated!");
          return;
        }

        if (isRunning && (isPeriodDifferent || !areAdditionalSettingsEqual))
          setIsConfirmModalOpen(true);
        else editDBC(additional_settings);
      } else beamtoast.error("Something went wrong! Please try again.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    DBCFileName,
    props.DBCData,
    DBCFileInput,
    props.selectedDBC,
    props.newDBC,
  ]);

  const stopAndUpdateDBCParser = async () => {
    setIsConfirmModalOpen(false);
    if (props.selectedDBC) {
      try {
        props.setLoading(true);
        await stopDBCParser(props.selectedDBC?.id);
        beamtoast.success(`Stopped DBC Parser "${props.selectedDBC.name}"`);
        editDBC(props.newDBC.additional_settings ?? {});
      } catch (e) {
        beamtoast.error(
          `Failed to stop DBC Parser "${props.selectedDBC.name}"`
        );
        props.setLoading(false);
        console.log(e);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    handleUpload,
  }));

  return props.operationType === DBCOperationType.Create ? (
    <Form>
      <Form.Field>
        <label htmlFor="upload_file">
          <p
            style={{
              fontSize: "1.1rem",
            }}
          >
            Upload file from the system
          </p>
        </label>
        <div style={{ position: "relative" }}>
          <StyledFileUploadButton
            fluid
            content="Select File"
            labelPosition="left"
            icon="file"
          />
          <StyledFileInput
            type="file"
            id="upload_file"
            ref={DBCFileInput}
            onChange={onSelect}
          />
        </div>
        <label htmlFor="file_name" style={{ marginTop: "12px" }}>
          File Chosen:{" "}
        </label>
        <span id="file_name">
          {DBCFileName !== "" ? DBCFileName : "No File Chosen"}
        </span>
      </Form.Field>
      {showDBCUploadProgress && (
        <Form.Field>
          <label htmlFor="file-progress">
            {uploadedDBCFileResponse.status === 0 ? (
              <span>
                File Uploading
                <AnimatedEllipsis spacing={3} dotSize={"8px"} />
              </span>
            ) : (
              <span>File Uploaded</span>
            )}
          </label>
          <progress
            id="file-progress"
            max={DBCFileTotal}
            value={DBCFileLoaded}
          />
        </Form.Field>
      )}
    </Form>
  ) : (
    <SimpleConfirmationModal
      open={isConfirmModalOpen}
      onClose={() => setIsConfirmModalOpen(false)}
      onConfirm={stopAndUpdateDBCParser}
      header="Restart Parser"
      content={
        "Changing the cycle time or additional settings would require the parser to be restarted. Are you sure?"
      }
    />
  );
});

interface CreateDBCModalProps {
  readonly open: boolean;
  readonly onOpen: () => void;
  readonly onClose: () => void;
  readonly selectedDBC?: DBCResponse;
  readonly DBCData: DBCResponse[];
  readonly operationType: string;
  setNewDBCName: (arg0: string) => void;
  handleUpdate: () => void;
}

export default function CreateOrEditDBCModal(props: CreateDBCModalProps) {
  const uploadDBCRef = useRef<handleUploadDBC>(null);
  const allStreams = useRef<string[]>([]);
  const validStreams = useRef<Stream>({});

  const [loading, setLoading] = useState<boolean>(true);
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [newDBC, setnewDBC] = useState<DBCData>({
    name: "",
    version: "",
    input_table: "",
    output_table: "",
    period: undefined,
    additional_settings: {
      dont_decode_choices: false,
      add_suffix_canid: false,
      lower_threshold: undefined,
      upper_threshold: undefined,
      dbc_ver: undefined,
    },
  });
  const [inputTableOptions, setInputTableOptions] = useState<
    {
      key: string;
      value: string;
      text: string;
    }[]
  >([]);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

  const setDBCName = (DBCName: string) => {
    setnewDBC({
      ...newDBC,
      name: DBCName,
    });
  };

  const setDBCVersion = (DBCVersion: string) => {
    setnewDBC({
      ...newDBC,
      version: DBCVersion,
    });
  };

  const setDBCInputTable = (DBCInputTableValue: string) => {
    setnewDBC({
      ...newDBC,
      input_table: DBCInputTableValue,
    });
  };

  const setDBCOutputTable = (DBCOutputTableValue: string) => {
    setnewDBC({
      ...newDBC,
      output_table: DBCOutputTableValue,
    });
  };

  const setDBCPeriod = (DBCPeriod: number) => {
    setnewDBC({
      ...newDBC,
      period: DBCPeriod,
    });
  };

  const updateSuffixCanidState = (state: boolean) => {
    setnewDBC({
      ...newDBC,
      additional_settings: {
        ...newDBC.additional_settings,
        add_suffix_canid: state,
      },
    });
  };

  const updateDisableEnumParsingState = (state: boolean) => {
    setnewDBC({
      ...newDBC,
      additional_settings: {
        ...newDBC.additional_settings,
        dont_decode_choices: state,
      },
    });
  };

  const setDBCLowerThreshold = (DBCLowerThreshold: number | undefined) => {
    setnewDBC({
      ...newDBC,
      additional_settings: {
        ...newDBC.additional_settings,
        lower_threshold: DBCLowerThreshold,
      },
    });
  };

  const setDBCUpperThreshold = (DBCUpperThreshold: number | undefined) => {
    setnewDBC({
      ...newDBC,
      additional_settings: {
        ...newDBC.additional_settings,
        upper_threshold: DBCUpperThreshold,
      },
    });
  };

  const setDBCVersionFilter = (DBCVersionFilter: number | undefined) => {
    setnewDBC({
      ...newDBC,
      additional_settings: {
        ...newDBC.additional_settings,
        dbc_ver: DBCVersionFilter,
      },
    });
  };

  const handleAccordionClick = () => {
    setActiveIndex((index) => (index === 0 ? 1 : 0));
  };

  useEffect(() => {
    const fn = async () => {
      let streamNames: string[] = [];
      const requiredFields = [
        "can_id",
        "byte1",
        "byte2",
        "byte3",
        "byte4",
        "byte5",
        "byte6",
        "byte7",
        "byte8",
        "sequence",
        "timestamp",
      ];

      const streams = await fetchAllStreamsWithDetails();

      for (const stream in streams) {
        const fields = streams[stream].fields;
        allStreams.current.push(stream);

        // check all mentioned fields above are present in the stream
        const allRequiredFieldsPresent = requiredFields.every((requiredField) =>
          Object.keys(fields).includes(requiredField)
        );

        if (allRequiredFieldsPresent) {
          /*
           * update valid streams list:
           ** validStreams contains list of streams with their data
           ** streamNames only contains a list of names of the valid streams
           */
          validStreams.current[stream] = streams[stream].fields;
          streamNames.push(stream);
        }
      }

      const tableOptions = dropDownOptionsFromArray(streamNames);
      setInputTableOptions(tableOptions);

      setLoading(false);
    };

    setLoading(true);
    fn();
  }, []);

  // disable submit button until all compulsory data is filled
  useEffect(() => {
    if (areAllKeysNotEmpty(newDBC)) setButtonDisabled(false);
    else setButtonDisabled(true);
  }, [newDBC]);

  useEffect(() => {
    const {
      selectedDBC: {
        name = "",
        version = "",
        input_table = "",
        output_table = "",
        period = undefined,
        additional_settings = {},
      } = {},
    } = props;

    const {
      dont_decode_choices = false,
      add_suffix_canid = false,
      lower_threshold = undefined,
      upper_threshold = undefined,
      dbc_ver = undefined,
    } = additional_settings || {};

    setnewDBC({
      name,
      version,
      input_table,
      output_table,
      period,
      additional_settings: {
        dont_decode_choices,
        add_suffix_canid,
        lower_threshold,
        upper_threshold,
        dbc_ver,
      },
    });
  }, [props.selectedDBC]); // eslint-disable-line react-hooks/exhaustive-deps

  // destructure for better code readability
  const add_suffix_canid = newDBC.additional_settings?.add_suffix_canid;
  const dont_decode_choices = newDBC.additional_settings?.dont_decode_choices;
  const upper_threshold = newDBC.additional_settings?.upper_threshold;
  const lower_threshold = newDBC.additional_settings?.lower_threshold;
  const dbc_ver = newDBC.additional_settings?.dbc_ver;

  return (
    <Modal
      className="dark"
      onClose={props.onClose}
      onOpen={props.onOpen}
      open={props.open}
    >
      <Modal.Header>
        {props.operationType === DBCOperationType.Create ? "Create" : "Edit"}{" "}
        DBC Parser
      </Modal.Header>
      <Modal.Content>
        {loading ? (
          <LoadingAnimation
            loaderContainerHeight="calc(100%)"
            fontSize="1.5rem"
            loadingText="Loading..."
          />
        ) : (
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <StyledInput labelPosition="left">
                  <StyledLabel>DBC Name</StyledLabel>
                  <input
                    placeholder="DBC Name"
                    value={newDBC.name}
                    onChange={(e) => setDBCName(e.target.value)}
                  />
                </StyledInput>
              </Grid.Column>
              <Grid.Column width={8}>
                <StyledInput labelPosition="left">
                  <StyledLabel>DBC Version</StyledLabel>
                  <input
                    placeholder="DBC Version"
                    value={newDBC.version}
                    onChange={(e) => setDBCVersion(e.target.value)}
                  />
                </StyledInput>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <StyledInput labelPosition="left">
                  <StyledLabel>Input Stream</StyledLabel>
                  <Dropdown
                    selection
                    fluid
                    search
                    placeholder="Stream name"
                    options={inputTableOptions}
                    value={newDBC.input_table}
                    disabled={props.operationType === DBCOperationType.Edit}
                    onChange={(_e, d) => {
                      if (d.value) setDBCInputTable(d.value.toString());
                    }}
                    style={{
                      border: "none",
                    }}
                  />
                </StyledInput>
              </Grid.Column>
              <Grid.Column width={8}>
                <StyledInput labelPosition="left">
                  <StyledLabel>Output Stream</StyledLabel>
                  <input
                    placeholder="Output Stream"
                    value={newDBC.output_table}
                    disabled={props.operationType === DBCOperationType.Edit}
                    onChange={(e) => setDBCOutputTable(e.target.value)}
                  />
                </StyledInput>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <StyledInput labelPosition="left">
                  <StyledLabel>Cycle Time (ms)</StyledLabel>
                  <input
                    placeholder="Cycle Time (ms)"
                    type="number"
                    value={newDBC.period === 0 ? undefined : newDBC.period}
                    onChange={(e) => setDBCPeriod(Number(e.target.value))}
                  />
                </StyledInput>
              </Grid.Column>
            </Grid.Row>
            {props.operationType === DBCOperationType.Create ? (
              <Grid.Row>
                <Grid.Column width={8}>
                  <UploadDBCFileSection
                    ref={uploadDBCRef}
                    onClose={props.onClose}
                    DBCData={props.DBCData}
                    newDBC={newDBC}
                    selectedDBC={props.selectedDBC}
                    setNewDBCName={(name: string) => props.setNewDBCName(name)}
                    allStreams={allStreams}
                    validStreams={validStreams}
                    operationType={props.operationType}
                    handleUpdate={() => props.handleUpdate()}
                    setLoading={setLoading}
                  />
                </Grid.Column>
              </Grid.Row>
            ) : (
              <UploadDBCFileSection
                ref={uploadDBCRef}
                onClose={props.onClose}
                DBCData={props.DBCData}
                newDBC={newDBC}
                selectedDBC={props.selectedDBC}
                setNewDBCName={(name: string) => props.setNewDBCName(name)}
                allStreams={allStreams}
                validStreams={validStreams}
                operationType={props.operationType}
                handleUpdate={() => props.handleUpdate()}
                setLoading={setLoading}
              />
            )}

            <Grid.Row>
              <Accordion inverted>
                <Accordion.Title
                  active={activeIndex === 0}
                  index={0}
                  onClick={handleAccordionClick}
                >
                  <Icon name="dropdown" />
                  <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                    Additional Settings
                  </span>
                </Accordion.Title>
                <Accordion.Content
                  active={activeIndex === 0}
                  style={{ paddingLeft: "20px" }}
                >
                  <Grid>
                    <Grid.Row style={{ marginTop: "1rem" }}>
                      <Grid.Column width={16}>
                        <Checkbox
                          checked={add_suffix_canid}
                          style={{ marginRight: "1rem" }}
                          disabled={
                            props.operationType === DBCOperationType.Edit
                          }
                          onChange={(e, { checked }) => {
                            updateSuffixCanidState(checked ? checked : false);
                          }}
                        />
                        <span
                          style={{
                            fontSize: "1.1rem",
                            cursor:
                              props.operationType === DBCOperationType.Edit
                                ? "not-allowed"
                                : "text",
                          }}
                        >
                          Add CAN ID as suffix to column names
                        </span>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <Checkbox
                          checked={dont_decode_choices}
                          style={{ marginRight: "1rem" }}
                          disabled={
                            props.operationType === DBCOperationType.Edit
                          }
                          onChange={(e, { checked }) => {
                            updateDisableEnumParsingState(
                              checked ? checked : false
                            );
                          }}
                        />
                        <span
                          style={{
                            fontSize: "1.1rem",
                            cursor:
                              props.operationType === DBCOperationType.Edit
                                ? "not-allowed"
                                : "text",
                          }}
                        >
                          Disable Enum Parsing
                        </span>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={12}>
                        <StyledInput labelPosition="left">
                          <StyledLabel>
                            Lower Threshold for flushing clusters (ms)
                          </StyledLabel>
                          <input
                            placeholder="value in ms"
                            type="number"
                            value={
                              lower_threshold === 0 || lower_threshold === null
                                ? undefined
                                : lower_threshold
                            }
                            onChange={(e) =>
                              setDBCLowerThreshold(
                                e.target.value
                                  ? Number(e.target.value)
                                  : undefined
                              )
                            }
                          />
                        </StyledInput>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={12}>
                        <StyledInput labelPosition="left">
                          <StyledLabel>
                            Upper Threshold for flushing clusters (ms)
                          </StyledLabel>
                          <input
                            placeholder="value in ms"
                            type="number"
                            value={
                              upper_threshold === 0 || upper_threshold === null
                                ? undefined
                                : upper_threshold
                            }
                            onChange={(e) =>
                              setDBCUpperThreshold(
                                e.target.value
                                  ? Number(e.target.value)
                                  : undefined
                              )
                            }
                          />
                        </StyledInput>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={12}>
                        <StyledInput labelPosition="left">
                          <StyledLabel>
                            DBC Version filter in CAN table on column dbc_ver
                          </StyledLabel>
                          <input
                            placeholder="value"
                            type="number"
                            value={
                              dbc_ver === 0 || dbc_ver === null
                                ? undefined
                                : dbc_ver
                            }
                            onChange={(e) =>
                              setDBCVersionFilter(
                                e.target.value
                                  ? Number(e.target.value)
                                  : undefined
                              )
                            }
                          />
                        </StyledInput>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Accordion.Content>
              </Accordion>
            </Grid.Row>
          </Grid>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          secondary
          onClick={() => {
            setnewDBC({
              name: "",
              version: "",
              input_table: "",
              output_table: "",
              period: undefined,
              additional_settings: {
                dont_decode_choices: false,
                add_suffix_canid: false,
                lower_threshold: undefined,
                upper_threshold: undefined,
                dbc_ver: undefined,
              },
            });
            setActiveIndex(-1);
            props.onClose();
          }}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          primary
          disabled={buttonDisabled}
          onClick={() => {
            if (uploadDBCRef.current) {
              uploadDBCRef.current.handleUpload();
            }
          }}
        >
          Submit
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
