import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Icon, Divider } from "semantic-ui-react";
import moment from "moment";
import { ThinDivider } from "../../Dashboards/Panel/util";
import { validateTimestampInterval } from "../../util";
import { FlexContainer } from "../../../common/ActionsUtils";
import {
  Filters,
  SearchDeviceResponse,
  Device,
  DeviceFilterOption,
  Key,
} from "../../../../BytebeamClient";
import { NestedDropdownOptions, ButtonPosition, getObjectDepth } from "../util";
import LoadingAnimation from "../../../common/Loader";
import { ErrorMessage } from "../../../common/ErrorMessage";
import ActionButtonV3 from "../ActionsV3/ActionButton";
import AdvancedPhasedSection from "./AdvancedPhasedSection";
import {
  NewActionLabelContainer,
  PhaseData,
  StyledNonBoldHeader,
} from "./NewAction";
import { beamtoast } from "../../../common/CustomToast";
import ActionFilterNestedDropdown from "../ActionsV3/ActionFilterNestedDropdown";
import SelectedFilter from "../ActionsV3/SelectedFilter";
import ActionDisplaySection from "./ActionDisplaySection";
import DeviceSelectionSection from "./DeviceSelectionSection";

export const PhaseSectionContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const AddFiltersButton = styled(Icon)`
  margin-right: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 15px !important;
  cursor: pointer;
`;

type PhaseErrorsMessage = { [key: string]: string | null };

type PhaseContentProps = {
  phase: PhaseData;
  phasesData: PhaseData[];
  setPhasesData: (phasesData: PhaseData[]) => void;
  activePhase: string;
  phaseErrorsMessage: PhaseErrorsMessage;
  setPhaseErrorsMessage: (
    error: (prevErrors: PhaseErrorsMessage) => PhaseErrorsMessage
  ) => void;
  filterLoading: boolean;
  filters: Filters;
  setFilters: (arg0: Filters) => void;
  debouncedUpdateFilters: any;
  filterOptions: DeviceFilterOption[];
  resetFilters: (arg0: string) => void;
  devices: SearchDeviceResponse;
  devicesLoading: boolean;
  setDevicesLoading: (arg0: boolean) => void;
  allSelected: boolean;
  setAllSelected: (allSelected: boolean) => void;
  selectedDevices: SearchDeviceResponse;
  setSelectedDevices: (selectedDevices: SearchDeviceResponse) => void;
  page: number;
  allowedFilterBys: Key[];
  pageLimit: number;
  onPageChange: (e, { activePage }) => void;
  metadataKeysToShow: string[];
  setMetadataKeysToShow: React.Dispatch<React.SetStateAction<string[]>>;
  currentDepth: number;
  setCurrentDepth: (arg0: number) => void;
  depth: number;
  setDepth: (arg0: number) => void;
  isPhasedRollout: boolean;
  selectedFilters: Filters;
  setSelectedFilters: (arg0: Filters) => void;
  filtersExist: boolean;
  setFiltersExist: (arg0: boolean) => void;
  shouldTriggerImmediately: boolean;
  setShouldTriggerImmediately: (shouldTriggerImmediately: boolean) => void;
};

const PhaseContent = ({
  phase,
  phasesData,
  setPhasesData,
  activePhase,
  phaseErrorsMessage,
  setPhaseErrorsMessage,
  filterLoading,
  filters,
  setFilters,
  debouncedUpdateFilters,
  filterOptions,
  resetFilters,
  devices,
  devicesLoading,
  setDevicesLoading,
  allSelected,
  setAllSelected,
  selectedDevices,
  setSelectedDevices,
  page,
  pageLimit,
  onPageChange,
  allowedFilterBys,
  metadataKeysToShow,
  setMetadataKeysToShow,
  isPhasedRollout,
  selectedFilters,
  setSelectedFilters,
  filtersExist,
  setFiltersExist,
  shouldTriggerImmediately,
  setShouldTriggerImmediately,
  currentDepth,
  setCurrentDepth,
  depth,
  setDepth,
}: PhaseContentProps) => {
  const [showFilterDropdown, setShowFilterDropdown] = useState<boolean>(false);
  const [showInsideFilterDropdown, setShowInsideFilterDropdown] =
    useState<boolean>(false);
  const [actionNestedDropdownOptions, setActionNestedDropdownOptions] =
    useState<NestedDropdownOptions[]>([]);
  const [showActionDisplayDropdown, setShowActionDisplayDropdown] =
    useState<boolean>(false);
  const [addFiltersButtonPosition, setAddFiltersButtonPosition] =
    useState<ButtonPosition>();

  const getNumericalDeviceIDs = (devices: Device[]) =>
    devices.map((device) => device.id);

  const updateActivePhaseTimestamp = (
    time: number | Date,
    activePhase: string
  ) => {
    // Validate if the timestamp has a 5-minute interval
    const isValidTimestamp = validateTimestampInterval(
      time,
      activePhase,
      phasesData,
      shouldTriggerImmediately
    );

    if (
      !isValidTimestamp &&
      activePhase !== "Phase I" &&
      phasesData.length > 1
    ) {
      const errorMsg =
        "Please ensure a minimum interval of 5 minutes from the previous phase with timestamp.";
      setPhaseErrorsMessage((prevErrors) => ({
        ...prevErrors,
        [activePhase]: errorMsg,
      }));
    } else {
      setPhaseErrorsMessage((prevErrors) => ({
        ...prevErrors,
        [activePhase]: null,
      }));
    }

    const newPhasesData = phasesData.map((phase) => {
      if (phase.name === activePhase) {
        phase.trigger_on = {
          timestamp: moment(time).toDate(),
          ...(phase.trigger_on.hasOwnProperty("failure_percentage")
            ? { failure_percentage: phase.trigger_on.failure_percentage }
            : {}),
        };
      }
      return phase;
    });
    setPhasesData(newPhasesData);
  };

  const updateActivePhaseFraction = (value: number, activePhase: string) => {
    const newPhasesData = phasesData.map((phase) => {
      if (phase.name === activePhase) {
        phase.info.fraction = parseInt(value.toString());
      }
      return phase;
    });
    setPhasesData(newPhasesData);
  };

  const updateSuccessPercentage = (value: number, activePhase: string) => {
    if (value < 0 || value > 100) {
      beamtoast.error("Success rate should be between 0 and 100");
      return;
    }

    const newPhasesData = phasesData.map((phase) => {
      if (phase.name === activePhase) {
        phase.trigger_on = {
          success_percentage: Number(value),
          ...(phase.trigger_on.failure_percentage
            ? { failure_percentage: phase.trigger_on.failure_percentage }
            : {}),
        };
      }
      return phase;
    });

    setPhasesData(newPhasesData);
  };

  const updateFailurePercentage = (
    value: number,
    phaseId: number,
    abortPhase: boolean
  ) => {
    if (value <= 0 || value > 100) {
      beamtoast.error("Failure percentage should be between 1 and 100");
      return;
    }

    const newPhasesData = phasesData.map((phase) => {
      if (phase.id === phaseId) {
        if (abortPhase) {
          phase.trigger_on.failure_percentage = Number(value);
        } else {
          delete phase.trigger_on.failure_percentage;
        }
      }
      return phase;
    });

    setPhasesData(newPhasesData);
  };

  const updateFixedListPhaseData = (updatedDevices: SearchDeviceResponse) => {
    let updatedPhaseData: PhaseData = { ...phasesData[phase.id - 1] };
    updatedPhaseData.info.device_ids = getNumericalDeviceIDs(
      updatedDevices.devices
    );
    updatedPhaseData.info.filter = filters;
    updatedPhaseData.info.fraction = 100;
    if (updatedDevices.devices.length === 0) {
      updatedPhaseData.info.type =
        Object.keys(filters).length === 0 ? "fixed-list" : "filter-fraction";
    } else {
      updatedPhaseData.info.type = "fixed-list";
    }

    let updatedPhasesData: PhaseData[] = [...phasesData];
    updatedPhasesData[phase.id - 1] = updatedPhaseData;
    setPhasesData(updatedPhasesData);
  };

  const selectDevice = (device: Device) => {
    let updatedDevices: SearchDeviceResponse = {
      devices: [...selectedDevices.devices, device],
      count: selectedDevices.count + 1,
    };
    setSelectedDevices(updatedDevices);

    updateFixedListPhaseData(updatedDevices);
  };

  const clearDevice = (device: Device) => {
    const filteredDevices = selectedDevices.devices.filter(
      (selectedDevice) => selectedDevice.id !== device.id
    );

    const updatedDevices: SearchDeviceResponse = {
      devices: filteredDevices,
      count: selectedDevices.count - 1,
    };
    setSelectedDevices(updatedDevices);

    updateFixedListPhaseData(updatedDevices);
  };

  const handleAllDevicesSelect = async (selectAll: boolean) => {
    setAllSelected(selectAll);

    let updatedPhaseData: PhaseData = { ...phasesData[phase.id - 1] };
    if (Object.keys(filters).length === 0 && !selectAll) {
      updatedPhaseData.info = {
        type: "fixed-list",
        device_ids: [],
      };
    } else {
      updatedPhaseData.info = {
        type: "filter-fraction",
        filter: filters,
        fraction: 100,
      };
    }
    let updatedPhasesData: PhaseData[] = [...phasesData];
    updatedPhasesData[phase.id - 1] = updatedPhaseData;
    setPhasesData(updatedPhasesData);
  };

  const onFilterChange = useCallback(
    (name: any, value: any) => {
      setDevicesLoading(true);

      const filterName = name;
      const filterValues = value as string[];

      const currentlySelectedFilters = { ...selectedFilters };
      if (filterValues.length > 0) {
        currentlySelectedFilters[filterName] = filterValues;
      } else {
        delete currentlySelectedFilters[filterName];
      }

      if (Object.keys(currentlySelectedFilters).length === 0) {
        setFiltersExist(false);
        setAllSelected(false);
      } else {
        setFiltersExist(true);
        setAllSelected(true);
      }

      setSelectedFilters(currentlySelectedFilters);
      setFilters(currentlySelectedFilters);
      debouncedUpdateFilters(currentlySelectedFilters, allowedFilterBys);
    },
    [
      selectedFilters,
      allowedFilterBys,
      setDevicesLoading,
      setSelectedFilters,
      debouncedUpdateFilters,
      setFiltersExist,
      setAllSelected,
      setFilters,
    ]
  );

  const getButtonPosition = (id: string) => {
    const buttonElement = document.getElementById(id);
    if (buttonElement) {
      const buttonPosition = buttonElement.getBoundingClientRect();
      setAddFiltersButtonPosition({
        top: buttonPosition.top,
        left: buttonPosition.left,
        width: buttonPosition.width,
        height: buttonPosition.height,
      });
    }
  };

  useEffect(() => {
    let array: NestedDropdownOptions[] = [];
    filterOptions.forEach((option) => {
      const children = option.filterValues.flatMap(
        (filterValue: string | number) => {
          const value = filterValue.toString().trim();
          return value ? [{ text: value, value }] : [];
        }
      );

      array.push({ label: option.filterName, children });
    });

    setActionNestedDropdownOptions(array);

    if (array.length !== 0) setDepth(getObjectDepth(array) - 2);
  }, [filterOptions, setDepth]);

  return (
    <PhaseSectionContainer
      id={`phaseContent-${phasesData.length + 2 - phase.id}`}
      style={{ display: activePhase === phase.name ? "block" : "none" }}
    >
      {filterOptions && filterOptions.length !== 0 && (
        <>
          <NewActionLabelContainer style={{ marginBottom: "20px" }}>
            <div
              style={{
                display: "flex",
                flexGrow: 1,
                justifyContent: "space-between",
              }}
            >
              <div style={{ minHeight: "35px" }}>
                <div
                  className="dashboard-header"
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <FlexContainer>
                    <div>
                      <ActionButtonV3
                        onClick={() => setShowInsideFilterDropdown(true)}
                        border_style={"dashed"}
                        label={"Filters"}
                        icon={"filter"}
                        margin_left="0px"
                        id={`create-action-metadata-filters-button-${phasesData.length + 2 - phase.id}`}
                      />
                      <div style={{ position: "relative" }}>
                        {showInsideFilterDropdown && (
                          <ActionFilterNestedDropdown
                            setShowDropdown={(value) =>
                              setShowInsideFilterDropdown(value)
                            }
                            depth={depth}
                            onClick={(filterOption, filterValue) =>
                              onFilterChange(filterOption, filterValue)
                            }
                            nestedDropdownOptions={actionNestedDropdownOptions}
                            filters={filters}
                            currentDepth={currentDepth}
                            setCurrentDepth={setCurrentDepth}
                            search
                          />
                        )}
                      </div>
                    </div>
                  </FlexContainer>
                </div>
              </div>
              {filtersExist && Object.keys(selectedFilters).length !== 0 && (
                <div style={{ alignSelf: "center" }}>
                  <StyledNonBoldHeader
                    as="h3"
                    style={{
                      marginTop: "0px",
                      marginBottom: "0px",
                      fontSize: "1.1rem",
                      whiteSpace: "nowrap",
                    }}
                    className={`${
                      Object.keys(selectedFilters).length === 0
                        ? "color-disabled"
                        : "selectable-item underline hover-underline color-blue"
                    }`}
                    onClick={() => {
                      setShowFilterDropdown(false);
                      setShowInsideFilterDropdown(false);
                      resetFilters(phase.name);
                    }}
                    id={`action-filter-clear-all-${phase.id}`}
                  >
                    Clear All
                  </StyledNonBoldHeader>
                </div>
              )}
            </div>
          </NewActionLabelContainer>

          {filtersExist && Object.keys(selectedFilters).length !== 0 && (
            <>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  margin: "0px 10px 10px 10px",
                  width: "100%",
                }}
              >
                {Object.keys(selectedFilters).map((key) => (
                  <SelectedFilter
                    key={key}
                    filterName={key}
                    filterValues={filters[key]}
                    filterNameIcon={"file"}
                    filterValueIcon={"info circle"}
                    onCrossClick={(filterOption, filterValue) =>
                      onFilterChange(filterOption, filterValue)
                    }
                    multiple
                  />
                ))}
                <AddFiltersButton
                  name="plus"
                  id={`action-add-filters-button-${phasesData.length + 2 - phase.id}`}
                  onClick={() => {
                    setShowFilterDropdown(true);
                    getButtonPosition("action-add-filters-button");
                  }}
                />
                <div style={{ position: "relative" }}>
                  {showFilterDropdown && (
                    <ActionFilterNestedDropdown
                      setShowDropdown={(value) => setShowFilterDropdown(value)}
                      depth={depth}
                      onClick={(filterOption, filterValue) =>
                        onFilterChange(filterOption, filterValue)
                      }
                      nestedDropdownOptions={actionNestedDropdownOptions}
                      filters={filters}
                      currentDepth={currentDepth}
                      setCurrentDepth={setCurrentDepth}
                      parentButtonPosition={addFiltersButtonPosition}
                      search
                    />
                  )}
                </div>
                <Divider />
              </div>
              <ThinDivider />
            </>
          )}
        </>
      )}

      {!filterLoading && !devicesLoading && devices?.devices?.length !== 0 && (
        <ActionDisplaySection
          showActionDisplayDropdown={showActionDisplayDropdown}
          setShowActionDisplayDropdown={setShowActionDisplayDropdown}
          setMetadataKeysToShow={setMetadataKeysToShow}
          metadataKeysToShow={metadataKeysToShow}
          allowedMetadataKeys={allowedFilterBys}
        />
      )}

      {(filterLoading || devicesLoading) && (
        <LoadingAnimation
          loaderContainerHeight="350px"
          loadingText="Loading devices"
          fontSize="20px"
          loaderSize="48px"
        />
      )}
      {!filterLoading && !devicesLoading && devices?.devices?.length !== 0 && (
        <>
          <DeviceSelectionSection
            devices={devices}
            allSelected={allSelected}
            setAllSelected={handleAllDevicesSelect}
            selectedDevices={selectedDevices}
            setSelectedDevices={setSelectedDevices}
            selectDevice={selectDevice}
            clearDevice={clearDevice}
            page={page}
            pageLimit={pageLimit}
            onPageChange={onPageChange}
            metadataKeysToShow={metadataKeysToShow}
            phase={activePhase}
          />
          <ThinDivider />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "42px 0px 36px 0px",
            }}
          >
            <AdvancedPhasedSection
              key={activePhase}
              isPhasedRollout={isPhasedRollout}
              phases={phasesData}
              activePhase={activePhase}
              phaseErrorsMessage={phaseErrorsMessage}
              updateActivePhaseTimestamp={updateActivePhaseTimestamp}
              updateActivePhaseFraction={updateActivePhaseFraction}
              updateFailurePercentage={updateFailurePercentage}
              updateSuccessPercentage={updateSuccessPercentage}
              shouldTriggerImmediately={shouldTriggerImmediately}
              setShouldTriggerImmediately={setShouldTriggerImmediately}
            />
          </div>
        </>
      )}
      {!filterLoading && !devicesLoading && devices?.devices.length === 0 && (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ErrorMessage
            marginTop="30px"
            marginBottom="30px"
            message={"No devices found!"}
          />
        </div>
      )}
    </PhaseSectionContainer>
  );
};

export default PhaseContent;
