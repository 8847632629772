import React from "react";
import { Icon, Button, Modal } from "semantic-ui-react";
import {
  activateComponentFirmware,
  activateFirmware,
  FirmwareType,
} from "../../../../../BytebeamClient";
import { beamtoast } from "../../../../common/CustomToast";
import { useUser } from "../../../../../context/User.context";

type ActivateFirmwareModalProps = {
  readonly isOpen: boolean;
  readonly close: () => void;
  readonly fillFirmwareTable: (
    showDeactivatedFirmwares?: boolean
  ) => Promise<void>;
  readonly firmwareVersion: FirmwareType;
  readonly toggleHandler: () => void;
};

function ActivateFirmwareModal(props: ActivateFirmwareModalProps) {
  const { isOpen, close, fillFirmwareTable, firmwareVersion, toggleHandler } =
    props;

  const { user } = useUser();
  const isComponentEnabled =
    user?.["tenant-settings"]?.show_tabs?.device_components?.show ?? false;

  async function activateFirmwareVersion() {
    try {
      if (isComponentEnabled) {
        await activateComponentFirmware(
          firmwareVersion.device_component_name ?? "",
          firmwareVersion?.version_number,
          !firmwareVersion?.is_deactivated
        );
      } else {
        await activateFirmware(
          firmwareVersion?.version_number,
          !firmwareVersion?.is_deactivated
        );
      }
      beamtoast.success(
        `${
          firmwareVersion?.is_deactivated ? "Activated" : "Deactivated"
        } firmware version ${firmwareVersion?.["version_number"]}`
      );
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <Modal open={isOpen} size="tiny" className="dark" onClose={close}>
      <Modal.Header>
        {firmwareVersion?.is_deactivated ? "Activate" : "Deactivate"} Firmware
        Version {firmwareVersion?.version_number}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <div
            style={{
              fontSize: "16px",
              fontWeight: 600,
              marginBottom: "8px",
            }}
          >
            Are you sure you want to{" "}
            {firmwareVersion?.is_deactivated ? "activate" : "deactivate"}{" "}
            firmware version <b>{firmwareVersion?.version_number}</b>?
          </div>
          <p
            style={{
              marginBottom: "12px",
            }}
          >
            Please note that if you proceed with{" "}
            {firmwareVersion?.is_deactivated ? "activating" : "deactivating"}{" "}
            the firmware version {firmwareVersion?.version_number}, you will{" "}
            {firmwareVersion?.is_deactivated ? "" : "no longer"} be able to
            update devices with this firmware version in the future. Would you
            still like to proceed?
          </p>
          Click the{" "}
          <b>{firmwareVersion?.is_deactivated ? "Activate" : "Deactivate"}</b>{" "}
          button to proceed with the{" "}
          {firmwareVersion?.is_deactivated ? "activation" : "deactivation"}{" "}
          process, or click the <b>Discard</b> button.
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          secondary
          onClick={() => {
            close();
          }}
        >
          <Icon name="remove" /> Discard
        </Button>
        <Button
          primary
          onClick={async () => {
            await activateFirmwareVersion();
            await fillFirmwareTable();
            toggleHandler();
            close();
          }}
        >
          <Icon name="checkmark" />{" "}
          {firmwareVersion?.is_deactivated ? "Activate" : "Deactivate"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default ActivateFirmwareModal;
